<div #menuBtn1 style="visibility: hidden; position: fixed; width:0px; height:0px;"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="menu"></div>
<mat-menu #menu="matMenu">
    <button mat-menu-item [disabled]="copyCutInProgress || previousVersionMode" (click)="copyCutAction(true)"><span jhiTranslate="document.copy">Copy</span></button>
    <button mat-menu-item [disabled]="copyCutInProgress || previousVersionMode" (click)="copyCutAction(false)"><span jhiTranslate="document.cut">Cut</span></button>
    <button mat-menu-item [disabled]="copyCutInProgress || !mergePDFsAvailable()" (click)="mergePDFs()"><span jhiTranslate="document.merge">Merge</span></button>
    <mat-divider *ngIf="copyCutInProgress"></mat-divider>
    <button mat-menu-item *ngIf="copyCutInProgress" (click)="finalizeCopyCut()"
            [disabled]="isCurrentDirectoryOrigin()"><span jhiTranslate="document.paste">Paste</span>
    </button>
    <button mat-menu-item *ngIf="copyCutInProgress" (click)="clearCopyCut()"><span jhiTranslate="document.cancel">Cancel</span></button>
    <mat-divider></mat-divider>
    <button mat-menu-item [disabled]="copyCutInProgress || previousVersionMode || !isOneSelected() || isRoot() || !this.canModifyCurrentDirectory" (click)="changeNameDialog()">
        <span jhiTranslate="changeNameAction.actionName">Change name</span>
    </button>
    <span *ngIf="ddvSettings.documentVersionEnable">
                    <button mat-menu-item [disabled]="!isOneDocumentSelected()" (click)="openUploadDocumentVersion()">
                        <span jhiTranslate="document.versioning">Change file version </span>
                    </button>
                    <button mat-menu-item [disabled]="!isPreviousVersionEnable()" (click)="showDocumentVersionOn()">
                        <span jhiTranslate="document.showVersions">Show versions</span>
                    </button>
                </span>
    <button mat-menu-item (click)="exportFileReport()"><span jhiTranslate="vdrApp.document.export.upload.report">Download upload report</span></button>
    <mat-divider></mat-divider>
    <button mat-menu-item class="button-square" [disabled]="!canDeleteFilesFromMenu" (click)="deleteFiles()">
        <span jhiTranslate="document.delete">Delete</span>
    </button>

</mat-menu>


<div fxLayout="column" style="background-color: #efeff2; height: 100%">
    <div class="button-row" fxLayout="row" fxFlexAlign="start center">
        <button
            mat-raised-button
            style="min-width: 70px"
            [matMenuTriggerFor]="filterMenu"
            (click)="filterOpen()"
            [disabled]="!directoryId || directoryId < 1">
                <mat-icon>filter_list</mat-icon>
            <span jhiTranslate="document.filter.title">Filter</span>
        </button>
        <div class="search-input" fxLayout="row">
            <div fxLayout="column">
                <input (keyup.enter)="documentSearch()"
                    type="text"
                    placeholder="{{'document.filter.search-phrase' | translate}}"
                    [(ngModel)]="documentSearchComponent.documentSearchRowCriteriaDTO.phrase"/>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div fxLayout="column" fxLayoutAlign="center">
                <mat-form-field class="search-input-type" style="height: 42px;" >
                    <mat-select [(ngModel)]="documentSearchComponent.documentSearchRowCriteriaDTO.searchIn"
                               fxLayout="column" fxLayoutAlign="center" style="text-align: center !important;"> <!-- placeholder="{{'document.filter.search-title' | translate}}" -->

                        <mat-option [value]="SearchIn[SearchIn.TITLES]" style="text-align: center !important">
                            <span jhiTranslate="document.filter.search-by-title" >by title</span>
                        </mat-option>
                        <mat-option [value]="SearchIn[SearchIn.CONTENT]" style="text-align: center !important">
                            <span jhiTranslate="document.filter.search-by-content">by content</span>
                        </mat-option>
                        <mat-option [value]="SearchIn[SearchIn.TITLES_AND_CONTENT]" style="text-align: center !important">
                            <span jhiTranslate="document.filter.search-by-title-and-content">by title and content</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div fxLayout="column">
                <button
                    mat-icon-button
                    type="button"
                    (click)="documentSearch()"
                    style="margin: 0;"
                    [disabled]="!documentSearchComponent.phrase">
                    <mat-icon>search</mat-icon>
                </button>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div fxLayout="column">
                <button
                    mat-icon-button
                    type="button"
                    (click)="documentSearchClear()"
                    style="margin: 0"
                    [disabled]="!documentSearchComponent.phrase && !documentSearchComponent.hasSearchResults">
                    <mat-icon>clear</mat-icon>
                </button>
            </div>
        </div>
        <mat-menu #filterMenu="matMenu" class="directory-tree-text" fxFlex>
            <div fxFlex fxLayout="column" fxLayoutAlign="center" *ngIf="documentCriteriaDtoTmp" (click)="$event.stopPropagation();">
                <div class="filter-item" fxLayout="column">
                    <mat-radio-group [(ngModel)]="documentCriteriaDtoTmp.read" class="mat-radio-group-wrap">
                        <mat-radio-button [value]="null" (click)="$event.stopPropagation()" color="warn" class="d-block">
                            <span jhiTranslate="document.filter.all">All</span>
                        </mat-radio-button>
                        <mat-radio-button [value]="false" (click)="$event.stopPropagation()" color="warn" class="d-block">
                            <span jhiTranslate="document.filter.unread">Unread</span>
                        </mat-radio-button>
                        <mat-radio-button [value]="true" (click)="$event.stopPropagation()" color="warn" class="d-block">
                            <span jhiTranslate="document.filter.read">Read</span>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <mat-divider></mat-divider>
                <div class="filter-item mt-2" fxLayout="column">
                    <mat-form-field>
                        <input
                            name="createdFrom"
                            matInput
                            [matDatepicker]="createdFromPicker"
                            [(ngModel)]="documentCriteriaDtoTmp.createdFrom"
                            placeholder="{{'document.filter.created-from' | translate}}"
                            (click)="$event.stopPropagation();">
                        <mat-datepicker-toggle matSuffix [for]="createdFromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #createdFromPicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field>
                        <input
                            name="createdTo"
                            matInput
                            [matDatepicker]="createdToPicker"
                            [(ngModel)]="documentCriteriaDtoTmp.createdTo"
                            placeholder="{{'document.filter.created-to' | translate}}"
                            (click)="$event.stopPropagation();">
                        <mat-datepicker-toggle matSuffix [for]="createdToPicker"></mat-datepicker-toggle>
                        <mat-datepicker #createdToPicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <div class="filter-item" fxLayout="column">
                    <label class="form-control-label" jhiTranslate="document.filter.file-format">File format</label>
                    <mat-selection-list
                        class="mb-2"
                        #documentTypeList
                        id="documentTypeList"
                        [(ngModel)]="documentCriteriaDtoTmp.documentTypes"
                        (click)="$event.stopPropagation();">
                            <mat-list-option
                                *ngFor="let documentType of documentTypesSelect"
                                [value]="documentType.key"
                                checkboxPosition="before">
                                {{documentType.value}}
                            </mat-list-option>
                    </mat-selection-list>
                </div>
                <mat-divider></mat-divider>
                <div class="filter-item mt-2" fxLayout="column">
                    <div align="right">
                        <button
                            mat-raised-button
                            color="warn"
                            (click)="search()">
                                <span jhiTranslate="document.filter.search">Search</span>
                        </button>
                    </div>
                </div>
            </div>
        </mat-menu>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" class="button-row" *ngIf="hasFilters()">
        <mat-form-field #chipList fxFlex="98%">
            <mat-chip-list>
                <mat-chip *ngIf="documentCriteriaDto.read == true" (removed)="chipsRemoveRead()">
                    <span jhiTranslate="document.filter.read">Read</span>
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-chip *ngIf="documentCriteriaDto.read == false" (removed)="chipsRemoveRead()">
                    <span jhiTranslate="document.filter.unread">Unread</span>
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-chip *ngIf="documentCriteriaDto.createdFrom" (removed)="chipsRemoveCreatedFrom()">
                    <span jhiTranslate="document.filter.created-from">Created from</span>
                    <span>&nbsp;{{documentCriteriaDto.createdFrom | date: 'yyyy-MM-dd'}}</span>
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-chip *ngIf="documentCriteriaDto.createdTo" (removed)="chipsRemoveCreatedTo()">
                    <span jhiTranslate="document.filter.created-to">Created to</span>
                    <span>&nbsp;{{documentCriteriaDto.createdTo | date: 'yyyy-MM-dd'}}</span>
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-chip *ngIf="documentCriteriaDto.documentTypes && documentCriteriaDto.documentTypes.length > 0" (removed)="chipsRemoveDocumentTypes()">
                    <span jhiTranslate="document.filter.file-format">File format</span>
                    <span>&nbsp;{{getDocumentTypesFilerText()}}</span>
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-chip color="warn" (removed)="chipsRemoveAll()">
                    <span jhiTranslate="document.filter.clear-filters">Clear filters</span>
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </mat-form-field>
    </div>
    <jhi-document-search #documentSearchComponent (onDocumentSearch)="search()" [documentCriteriaDto]="documentCriteriaDto"></jhi-document-search>
    <div *ngIf="!documentSearchComponent.hasSearched">
        <div fxLayout="row" class="button-row">
            <div fxFlexAlign="start" fxFlex="60%" >
                <span *ngIf="!previousVersionMode && nodeType!=='QA'">
                    <button *ngIf="canModifyCurrentDirectory" mat-raised-button color="warn" (click)="openDialog()" [disabled]="!directoryId || directoryId<0"><span jhiTranslate="document.upload">Upload</span></button>
                    <button *jhiHasAnyAuthority="'DOCUMENT_WRITE'" mat-raised-button (click)="createDirectoryDialog()"><span jhiTranslate="directory.new">New folder</span></button>
                    <button *jhiHasAnyAuthority="'DOCUMENT_WRITE'" mat-raised-button color="warn" routerLink="/permission-view"><span jhiTranslate="document.security">Security</span></button>
                </span>
                <button mat-raised-button color="warn" *ngIf="previousVersionMode" (click)="showDocumentVersionOff()"><span jhiTranslate="document.showDocumentVersionOff">Return</span></button>
            </div>
            <div align="right" fxFlex="38%">
                <button mat-raised-button class="button-square" (click)="synchronizeList()">
                    <mat-icon>sync</mat-icon>
                </button>
                <button *jhiHasAnyAuthority="'DOCUMENT_WRITE'" mat-raised-button class="button-square" [disabled]="!(canModifyCurrentDirectory | deleteFilesAvailable:nodeType:selection)" (click)="deleteFiles()">
                    <i class="fa fa-trash " style="font-size: 17px" ></i>
                </button>
                <button mat-raised-button color="warn" class="button-square" [matMenuTriggerFor]="downloadMenu" [disabled]="nodeType==='QA'">
                    <mat-icon>get_app</mat-icon>
                </button>
                <button mat-raised-button class="button-square" (click)="onContextMenu($event)"  [disabled]="nodeType==='QA'">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </div>

            <mat-menu #downloadMenu="matMenu">
                <button mat-menu-item [disabled]="!areFilesSelected() || copyCutInProgress || !allSelectedPDFSCanBeDownloaded()" (click)="downloadFiles('PDF')">PDF</button>
                <button mat-menu-item [disabled]="!areFilesSelected() || copyCutInProgress || !allSelectedOriginalsCanBeDownloaded()" (click)="downloadFiles('ORIGINAL')"><span jhiTranslate="document.original">Original</span></button>
                <button mat-menu-item [disabled]="!areFilesSelected() || copyCutInProgress || !allSelectedPDFSCanBeDownloadedEncrypted()" (click)="downloadFiles('PDF_PASSWORD_PROTECTED')"><span jhiTranslate="document.pdfPasswordProtected">Zaszyfrowany PDF</span></button>
                <button mat-menu-item *jhiHasAnyAuthority="['ADMIN', 'ADMIN_LIMITED']"
                        [disabled]="!areFilesSelected() || copyCutInProgress || !allSelectedIsDirectory()" (click)="createArchive()">
                    <span jhiTranslate="document.archive">Archive</span>
                </button>
            </mat-menu>
        </div>
        <mat-divider></mat-divider>
        <div class="Aligner">
            <mat-table [dataSource]="dataSource" matSort style="width: 96%" class="documentList" >
                <mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="getNavigationUrl(row)" class="row-hover"></mat-row>

                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="toggleAll($event)"
                                      [checked]="selection.hasValue() && isAllSelected()"
                                      [indeterminate]="selection.hasValue() && !isAllSelected()" color="warn" [disabled]="copyCutInProgress">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                      (change)="selectRow($event, row)"
                                      [checked]="selection.isSelected(row)" color="warn" [disabled]="copyCutInProgress || row.status!='NORMAL'">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="favourite" >
                    <mat-header-cell mat-header-cell *matHeaderCellDef style="max-width: 60px !important;">
                        <button
                            mat-icon-button
                            *ngIf="documentCriteriaDto.favourite"
                            (click)="favouriteChange()"
                            title="{{'document.favourite' | translate}}">
                            <mat-icon class="favourite-icon">star</mat-icon>
                        </button>
                        <button
                            mat-icon-button
                            *ngIf="!documentCriteriaDto.favourite"
                            (click)="favouriteChange()"
                            title="{{'document.favourite' | translate}}">
                            <mat-icon class="favourite-icon">star_border</mat-icon>
                        </button>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="max-width: 60px !important">
                        <button mat-icon-button *ngIf="element.type!='directory' && element.favourite"
                                (click)="favouriteOff(element.id); $event.stopPropagation()"
                                title="{{'document.favourite' | translate}}">
                            <mat-icon class="favourite-icon">star</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="element.type!='directory' && !element.favourite"
                                (click)="favouriteOn(element.id); $event.stopPropagation()"
                                title="{{'document.favourite' | translate}}">
                            <mat-icon class="favourite-icon">star_border</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef class="file-type-column"></mat-header-cell>
                    <mat-cell *matCellDef="let element" class="file-type-column">
                        <i *ngIf="element.type != 'directory'" class="{{getIcon(element.type)}}" style="font-size: 18px"
                          ></i>
                        <span class="dir-move-link">
                            <i *ngIf="element.type === 'directory'"
                               [ngStyle]="{'color': getColor(element), 'margin-left':getFolderMargin(element) }" [class]="(element.id != directoryId) ? 'fa fa-folder' : 'fa fa-folder-open'"
                               style="font-size: 18px">
                            </i>
                        </span>
                    </mat-cell>
                </ng-container>

                <span *ngIf="previousVersionMode">
                    <ng-container matColumnDef="version">
                        <mat-header-cell *matHeaderCellDef mat-sort-header class="file-type-column"><span jhiTranslate="document.version">VERSION</span></mat-header-cell>
                        <mat-cell *matCellDef="let element"  class="file-type-column">
                            <span>{{element.documentVersion}}</span>
                        </mat-cell>
                    </ng-container>
                </span>

                <span *ngIf="!previousVersionMode && ddvSettings.indexVisible">
                    <ng-container matColumnDef="number">
                        <mat-header-cell *matHeaderCellDef mat-sort-header class="file-type-column"><span jhiTranslate="document.index">INDEX</span></mat-header-cell>
                        <span *ngIf="nodeType!='QA'">
                            <mat-cell *matCellDef="let element"  class="file-type-column" matTooltip="{{element.number | listIndex: indexCumulate: (element.type === 'directory' && element.id == directoryId): true}}">
                                <span>{{element.number | listIndex: indexCumulate: (element.type === 'directory' && element.id == directoryId): false}}</span>
                            </mat-cell>
                        </span>
                        <span *ngIf="nodeType=='QA'">
                            <mat-cell *matCellDef="let element"  class="file-type-column" matTooltip="{{element.qaIndex}}">
                                <span>{{element.qaIndex}}</span>
                            </mat-cell>
                        </span>
                    </ng-container>
                </span>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> <span jhiTranslate="document.name">NAME</span></mat-header-cell>
                    <span *ngIf="nodeType!='QA'">
                        <mat-cell *matCellDef="let element" [matTooltip]="getTooltipText(element)" matTooltipPosition="below" matTooltipClass="my-tooltip"
                                   [dropData]="element"
                                  (contextmenu)="onContextMenu($event, element)"
                                  [dragAxis]="{x: element.status == 'NORMAL', y: element.status == 'NORMAL'}"
                                  [ghostDragEnabled]="true" [ghostElementTemplate]="simpleRow"
                                  [showOriginalElementWhileDragging]="true"
                                  [dragActiveClass]="'minimal-row'" style="font-size: 13px;" class="text-with-dots">
                            <span class="pdf-viewer-link" *ngIf="element.type != 'directory' && element.pdfSupport">
                                {{element.name }}
                            </span>
                            <span class="pdf-viewer-link" *ngIf="element.type != 'directory' && !element.pdfSupport && element.effectivePermissions.includes('DOWNLOAD_ORIGINAL')">
                                <a (click)="downloadFile(element.id)"> {{element.name}}</a>
                            </span>
                            <span class="pdf-viewer-link" *ngIf="element.type != 'directory' && !element.pdfSupport && !element.effectivePermissions.includes('DOWNLOAD_ORIGINAL')">
                                <a (click)="downloadFileError()"> {{element.name}}</a>
                            </span>
                            <span class="dir-move-link" *ngIf="element.type === 'directory'">{{element.name }}</span>
                            <ng-template #simpleRow NgIf="element" >
                                <div fxLayout="row" class="minimal-row">
                                    <i *ngIf="element.type != 'directory'" class="{{getIcon(element.type)}}" style="font-size: 18px !important;"
                                    ></i>
                                    <i *ngIf="element.type === 'directory'"
                                       [class]="'fa fa-folder'"
                                       style="font-size: 18px"></i>
                                    <span >&nbsp;{{element.name}}</span>
                                </div>
                            </ng-template>
                        </mat-cell>
                    </span>
                    <span *ngIf="nodeType=='QA'">
                        <mat-cell *matCellDef="let element" matTooltip="{{element.name }}" matTooltipPosition="below"
                                  style="font-size: 13px;" class="text-with-dots">
                            <span class="pdf-viewer-link" *ngIf="element.type != 'directory' && element.pdfSupport">
                                {{ element.name }}
                            </span>
                            <span class="pdf-viewer-link" *ngIf="element.type != 'directory' && !element.pdfSupport && element.effectivePermissions.includes('DOWNLOAD_ORIGINAL')">
                                <a (click)="downloadFile(element.id)"> {{element.name}}</a>
                            </span>
                            <span class="pdf-viewer-link" *ngIf="element.type != 'directory' && !element.pdfSupport && !element.effectivePermissions.includes('DOWNLOAD_ORIGINAL')">
                                <a (click)="downloadFileError()"> {{element.name}}</a>
                            </span>
                            <ng-template #simpleRow NgIf="element" >
                                <div fxLayout="row" class="minimal-row">
                                    <i *ngIf="element.type != 'directory'" class="{{getIcon(element.type)}}" style="font-size: 18px !important;"
                                    ></i>
                                    <i *ngIf="element.type === 'directory'"
                                       [class]="'fa fa-folder'"
                                       style="font-size: 18px"></i>
                                    <span>&nbsp;{{element.name}}</span>
                                </div>
                            </ng-template>
                        </mat-cell>
                    </span>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef  style="justify-content:flex-end !important; max-width: 50px !important">
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"  style="justify-content:flex-end !important; max-width: 50px !important ">
                        <span *ngIf="nodeType!='QA'">
                            <button
                                    mat-icon-button
                                    (click)="delete(element)"
                                    class="hoverButton"
                                    style="width: 20px"
                                    [disabled]="(element.type === 'directory' && !element.parentDirectoryId) || !this.canModifyCurrentDirectory">
                                <i class="fa fa-trash " style="font-size: 18px" ></i>
                            </button>
                        </span>
                        <!--*ngIf="checkDownloadType(element, 'DOWNLOAD_PDF') || checkDownloadType(element, 'DOWNLOAD_ORIGINAL') || checkDownloadType(element, 'DOWNLOAD_ENCRYPTED')"-->
                        <button  mat-icon-button [matMenuTriggerFor]="downlMenu" class="hoverButton"
                                *ngIf="(element | checkDownloadType:downloadTypes[0]) || (element | checkDownloadType:downloadTypes[1]) || (element | checkDownloadType:downloadTypes[2])"
                                 style="width: 30px; padding-right: 5px">
                            <mat-icon style="font-size: 18px">get_app</mat-icon>
                        </button>

                        <mat-menu #downlMenu="matMenu">
                            <!--[disabled]="!checkDownloadType(element, 'DOWNLOAD_PDF')"-->
                            <button mat-menu-item [disabled]="!(element | checkDownloadType:downloadTypes[0])"
                                    (click)="download('PDF', element)">PDF
                            </button>
                            <!--[disabled]="!checkDownloadType(element, 'DOWNLOAD_ORIGINAL')"-->
                            <button mat-menu-item [disabled]="!(element | checkDownloadType:downloadTypes[1])"
                                    (click)="download('ORIGINAL', element)"><span jhiTranslate="document.original">Original</span>
                            </button>
                            <!--[disabled]="!checkDownloadType(element, 'DOWNLOAD_ENCRYPTED')"-->
                            <button mat-menu-item [disabled]="!(element | checkDownloadType:downloadTypes[2])"
                                    (click)="download('PDF_PASSWORD_PROTECTED', element)"><span
                                jhiTranslate="document.pdfPasswordProtected">Zaszyfrowany PDF</span></button>
                        </mat-menu>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="newDocument">
                    <mat-header-cell *matHeaderCellDef style="max-width: 60px !important; padding-left: 5px"></mat-header-cell>
                    <mat-cell *matCellDef="let element" style="max-width: 60px !important; padding-left: 5px">
                        <div *ngIf="element.new" class="new-document-label" fxLayout="column" fxLayoutAlign="center center">
                            <mat-icon >fiber_new</mat-icon>
                        </div>
                        <div *ngIf="element.annotated" fxLayout="column" fxLayoutAlign="center center" matTooltip="{{'document.annotated' | translate}}">
                            <mat-icon >edit</mat-icon>
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="createdDate">
                    <mat-header-cell sortingCache *matHeaderCellDef mat-sort-header style="max-width: 140px !important; justify-content:flex-end !important"><span jhiTranslate="document.created"></span></mat-header-cell>
                    <mat-cell matTooltip="{{element.createdDate | date:'dd.MM.yyyy HH:mm'}}" *matCellDef="let element" style="max-width:
                    100px !important; justify-content:flex-end !important">
                        {{element.createdDate | date:'dd.MM.yyyy'}}</mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <span *ngIf="nodeType!='QA'">
                    <mat-row   *matRowDef="let row; columns: displayedColumns;" mwlDroppable (drop)="dropped($event, row)">
                    </mat-row>
                </span>
                <span *ngIf="nodeType=='QA'">
                    <mat-row *matRowDef="let row; columns: displayedColumns;">
                    </mat-row>
                </span>
            </mat-table>
        </div>
    </div>
</div>



<mat-menu #contextMenu="matMenu">
        <button mat-menu-item>Action 1</button>
        <button mat-menu-item>Action 2</button>
    </mat-menu>
