import { BaseEntity } from './../../shared';

export class DirectoryVdr implements BaseEntity {
    constructor(
        public id?: number,
        public name?: string,
        public description?: string,
        public number?: number,
        public projectId?: number,
        public childDirectories?: BaseEntity[],
        public parentDirectoryId?: number,
        public version?: number,
        public createdDate?: string,
        public fullPath?: string
    ) {
    }
}
