import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {JhiLanguageService} from '@upside-cloud/ng-jhipster';

import {RegistrationService} from './register.service';
import {EMAIL_ALREADY_USED_TYPE, JhiLanguageHelper, LOGIN_ALREADY_USED_TYPE, LoginModalService} from '../../shared';
import {ActivatedRoute} from '@angular/router';
import SecurityQuestion from '../security/question-reset/question-reset.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {SearchCountryField} from 'ngx-intl-tel-input-gg';
import {CountryISO} from 'ngx-intl-tel-input-gg';



type PasswordElements = {
    hasSmallLetter: boolean
    hasBigLetter: boolean
    hasDigit: boolean
    hasSpecialCharacter: boolean
    hasCorrectLength: boolean
};


@Component({
    selector: 'jhi-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.scss']
})
export class RegisterComponent implements OnInit, AfterViewInit {
    doNotMatch: string;
    error: string;
    errorEmailExists: string;
    errorUserExists: string;
    formHidden: boolean;
    modalRef: NgbModalRef;
    registrationKey: string;
    securityQuestions: SecurityQuestion[] = [];
    languages: any[] = [];
    currentLanguage: any;
    password1Show = false;
    password2Show = false;
    keyExpired: boolean;
    success: boolean;
    registrationForm: FormGroup = this.formBuilder.group({
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        company: ['', [Validators.required]],
        companyTitle: ['', [Validators.required]],
        phoneNumber: ['', [Validators.required, Validators.maxLength(16)]],
        password: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(40)]],
        confirmPassword: ['', [Validators.required, this.matchValues('password')]],
        securityQuestion: ['', [Validators.required]],
        securityAnswer: ['', [Validators.required]],
    });
    validationFailed: boolean;
    passwordElements: PasswordElements;


    constructor(private languageService: JhiLanguageService,
                private loginModalService: LoginModalService,
                private registerService: RegistrationService,
                private route: ActivatedRoute,
                private languageHelper: JhiLanguageHelper,
                private spinner: NgxSpinnerService,
                private formBuilder: FormBuilder
    ) {
    }

    matchValues(matchTo: string): (AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): ValidationErrors | null => {
            console.log(control.value, control?.parent?.controls[matchTo]?.value);
            return !!control.parent &&
            !!control.parent.value &&
            control.value === control.parent.controls[matchTo].value
                ? null
                : {isMatching: false};
        };
    }

    ngOnInit() {
        this.spinner.show();
        this.languageHelper.getAll().then((languages) => {
            this.languages = languages;
            this.languageService.getCurrent().then((value) => {
                this.currentLanguage = value;
            });
        });

        this.registrationKey = this.route.snapshot.queryParams['key'];
        this.registerService.verifyRegistrationKey(this.registrationKey).subscribe((registrationKeyValid) => {
            this.formHidden = !registrationKeyValid;
            this.keyExpired = !registrationKeyValid;
            this.spinner.hide();
            console.log('Is invitation valid?', registrationKeyValid, this.registrationKey);
        });

        this.registerService.getSecurityQuestions().subscribe((questions) => {
            this.securityQuestions.push(...questions);
            console.log('Security questions', this.securityQuestions);

        });
    }

    changeLanguage($event) {
        this.currentLanguage = $event.value;
        this.languageService.changeLanguage(this.currentLanguage);
    }

    ngAfterViewInit() {

    }

    checkPasswordElements(password: string): PasswordElements {
        const containsDigit = /[0-9]+/.test(password);
        const containsSmallLetter = /[a-z]+/.test(password);
        const containsBigLetter = /[A-Z]+/.test(password);
        const containsSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);

        return <PasswordElements>{
            hasBigLetter: containsBigLetter,
            hasDigit: containsDigit,
            hasSmallLetter: containsSmallLetter,
            hasSpecialCharacter: containsSpecialCharacter,
            hasCorrectLength: password.length >= 12
        };
    }

    register() {
        console.log(this.registrationForm);
        if (!this.registrationForm.valid) {
            this.registrationForm.markAllAsTouched();
            this.validationFailed = true;
            return;
        }

        this.validationFailed = false;

        const request = {
            ...this.registrationForm.value,
            registrationKey: this.registrationKey,
        };

        request.phoneNumber = request.phoneNumber.e164Number;


        this.doNotMatch = null;
        this.error = null;
        this.errorUserExists = null;
        this.errorEmailExists = null;
        this.languageService.getCurrent().then((key) => {
            request.langKey = key;
            this.registerService.save(request).subscribe(() => {
                this.formHidden = true;
                this.success = true;
                this.openLoginModal();
            }, (response) => this.processError(response));
        });
    }

    private processError(response) {
        this.formHidden = false;
        if (response.status === 400 && response.error.type === LOGIN_ALREADY_USED_TYPE) {
            this.errorUserExists = 'ERROR';
        } else if (response.status === 400 && response.error.type === EMAIL_ALREADY_USED_TYPE) {
            this.errorEmailExists = 'ERROR';
        } else {
            this.error = 'ERROR';
        }
    }

    openLoginModal() {
        this.modalRef = this.loginModalService.open('activate.messages.success');
    }

    onPasswordInputChange(password: string) {
        this.passwordElements = this.checkPasswordElements(password);
    }

    protected readonly SearchCountryField = SearchCountryField;
    protected readonly CountryISO = CountryISO;
}
