<ng-container [ngTemplateOutlet]="customSecondaryToolbar ? customSecondaryToolbar : defaultSecondaryToolbar"> </ng-container>

<ng-template #defaultSecondaryToolbar>
  <div
    id="secondaryToolbar"
    class="secondaryToolbar hidden doorHangerRight"
    [style.top]="secondaryToolbarTop"
    [style.transform]="'scale(' + mobileFriendlyZoomScale + ')'"
    [style.transformOrigin]="'right top'"
  >
    <div id="secondaryToolbarButtonContainer">
      <button
        type="button"
        id="secondaryPresentationMode"
        class="secondaryToolbarButton"
        [class]="showPresentationModeButton | negativeResponsiveCSSClass : 'visibleLargeView'"
        title="Switch to Presentation Mode"
        data-l10n-id="presentation_mode"
      >
        <svg style="width: 22px; height: 22px" viewBox="0 0 24 24">
          <path fill="currentColor" d="M5,5H10V7H7V10H5V5M14,5H19V10H17V7H14V5M17,14H19V19H14V17H17V14M10,17V19H5V14H7V17H10Z" />
        </svg>
        <span data-l10n-id="presentation_mode_label">Presentation Mode</span>
      </button>

      <button
        type="button"
        id="secondaryOpenFile"
        class="secondaryToolbarButton"
        [class]="showOpenFileButton | negativeResponsiveCSSClass : 'visibleSmallView'"
        title="Open File"
        data-l10n-id="open_file"
      >
        <svg style="width: 22px; height: 22px" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M12,12L16,16H13.5V19H10.5V16H8L12,12Z"
          />
        </svg>
        <span data-l10n-id="open_file_label">Open</span>
      </button>

      <button
        type="button"
        id="secondaryPrint"
        class="secondaryToolbarButton"
        [class]="showPrintButton | negativeResponsiveCSSClass : 'visibleSmallView'"
        title="Print"
        data-l10n-id="print"
      >
        <svg style="width: 22px; height: 22px" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M18,3H6V7H18M19,12A1,1 0 0,1 18,11A1,1 0 0,1 19,10A1,1 0 0,1 20,11A1,1 0 0,1 19,12M16,19H8V14H16M19,8H5A3,3 0 0,0 2,11V17H6V21H18V17H22V11A3,3 0 0,0 19,8Z"
          />
        </svg>
        <span data-l10n-id="print_label">Print</span>
      </button>

      <button
        type="button"
        id="secondaryDownload"
        class="secondaryToolbarButton"
        [class]="showDownloadButton | negativeResponsiveCSSClass : 'visibleSmallView'"
        title="Download"
        data-l10n-id="download"
      >
        <svg style="width: 22px; height: 22px" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M12,19L8,15H10.5V12H13.5V15H16L12,19Z"
          />
        </svg>
        <span data-l10n-id="download_label">Download</span>
      </button>

      <button
        type="button"
        id="firstPage"
        class="secondaryToolbarButton firstPage"
        [class]="showPagingButtons | negativeResponsiveCSSClass : 'visibleLargeView'"
        title="Go to First Page"
        data-l10n-id="first_page"
      >
        <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
          <path fill="currentColor" d="M18.41,16.59L13.82,12L18.41,7.41L17,6L11,12L17,18L18.41,16.59M6,6H8V18H6V6Z" />
        </svg>
        <span data-l10n-id="first_page_label">Go to First Page</span>
      </button>
      <button
        type="button"
        [class]="showPagingButtons | negativeResponsiveCSSClass : 'visibleTinyView'"
        id="previousPage"
        class="secondaryToolbarButton previousPage"
        title="Go to Previous Page"
        data-l10n-id="previous"
        [disabled]="disablePreviousPage"
        (click)="previousPage()"
      >
        <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
          <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
        </svg>
        <span data-l10n-id="previous_label">Go to Previous Page</span>
      </button>
      <button
        type="button"
        [disabled]="disableNextPage"
        id="nextPage"
        class="secondaryToolbarButton nextPage"
        [class]="showPagingButtons | negativeResponsiveCSSClass : 'visibleTinyView'"
        title="Go to Next Page"
        data-l10n-id="next"
        (click)="nextPage()"
      >
        <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
          <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
        </svg>
        <span data-l10n-id="next_label">Go to Next Page</span>
      </button>
      <button
        type="button"
        [class]="showPagingButtons | negativeResponsiveCSSClass : 'visibleLargeView'"
        id="lastPage"
        class="secondaryToolbarButton lastPage"
        title="Go to Last Page"
        data-l10n-id="last_page"
      >
        <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
          <path fill="currentColor" d="M5.59,7.41L10.18,12L5.59,16.59L7,18L13,12L7,6L5.59,7.41M16,6H18V18H16V6Z" />
        </svg>
        <span data-l10n-id="last_page_label">Go to Last Page</span>
      </button>

      <button
        type="button"
        [class]="showRotateButton | negativeResponsiveCSSClass : 'visibleXLView'"
        id="pageRotateCw"
        class="secondaryToolbarButton rotateCw"
        title="Rotate Clockwise"
        data-l10n-id="page_rotate_cw"
      >
        <svg style="width: 22px; height: 22px" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M12 3C7.03 3 3 7.03 3 12S7.03 21 12 21C14 21 15.92 20.34 17.5 19.14L16.06 17.7C14.87 18.54 13.45 19 12 19C8.13 19 5 15.87 5 12S8.13 5 12 5 19 8.13 19 12H16L20 16L24 12H21C21 7.03 16.97 3 12 3"
          />
        </svg>
        <span data-l10n-id="page_rotate_cw_label">Rotate Clockwise</span>
      </button>
      <button
        type="button"
        [class]="showRotateButton | negativeResponsiveCSSClass : 'visibleXLView'"
        id="pageRotateCcw"
        class="secondaryToolbarButton rotateCcw"
        title="Rotate Counterclockwise"
        data-l10n-id="page_rotate_ccw"
      >
        <svg style="width: 22px; height: 22px" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M12 3C7.03 3 3 7.03 3 12H0L4 16L8 12H5C5 8.13 8.13 5 12 5S19 8.13 19 12 15.87 19 12 19C10.55 19 9.13 18.54 7.94 17.7L6.5 19.14C8.08 20.34 10 21 12 21C16.97 21 21 16.97 21 12S16.97 3 12 3"
          />
        </svg>
        <span data-l10n-id="page_rotate_ccw_label">Rotate Counterclockwise</span>
      </button>

      <button
        type="button"
        id="cursorSelectTool"
        class="secondaryToolbarButton toggled"
        [class]="showHandToolButton | negativeResponsiveCSSClass : 'visibleXXLView'"
        title="Enable Text Selection Tool"
        data-l10n-id="cursor_text_select_tool"
      >
        <svg style="width: 22px; height: 22px" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M2 4C2 2.89 2.9 2 4 2H7V4H4V7H2V4M22 4V7H20V4H17V2H20C21.1 2 22 2.89 22 4M2 20V17H4V20H7V22H4C2.9 22 2 21.11 2 20M10 2H14V4H10V2M10 20H14V22H10V20M2 10H4V14H2V10M18.5 13C20.4 13 22 14.6 22 16.5C22 19.1 18.5 23 18.5 23C18.5 23 15 19.1 15 16.5C15 14.6 16.6 13 18.5 13M18.5 17.8C19.2 17.8 19.8 17.2 19.7 16.6C19.7 16 19.1 15.4 18.5 15.4C17.9 15.4 17.3 15.9 17.3 16.6C17.3 17.2 17.8 17.8 18.5 17.8M20 10H22V12.34C21.42 11.84 20.74 11.45 20 11.23V10Z"
          />
        </svg>
        <span data-l10n-id="cursor_text_select_tool_label">Text Selection Tool</span>
      </button>
      <button
        type="button"
        id="cursorHandTool"
        class="secondaryToolbarButton"
        [class]="showHandToolButton | negativeResponsiveCSSClass : 'visibleXXLView'"
        title="Enable Hand Tool"
        data-l10n-id="cursor_hand_tool"
      >
        <svg style="width: 22px; height: 22px" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M13,6V11H18V7.75L22.25,12L18,16.25V13H13V18H16.25L12,22.25L7.75,18H11V13H6V16.25L1.75,12L6,7.75V11H11V6H7.75L12,1.75L16.25,6H13Z"
          />
        </svg>
        <span data-l10n-id="cursor_hand_tool_label">Hand Tool</span>
      </button>

      <button
        type="button"
        [class]="showScrollingButton | negativeResponsiveCSSClass : ''"
        id="scrollPage"
        class="secondaryToolbarButton scrollPage"
        title="Use Page Scrolling"
        data-l10n-id="scroll_page"
      >
        <svg style="width: 24px; height: 24px; margin-top: 3px">
          <path
            fill="currentColor"
            d="M9.5 4c1 0 1.5.5 1.5 1.5v5c0 1-.5 1.5-1.5 1.5h-3c-1 0-1.5-.5-1.5-1.5v-5C5 4.5 5.5 4 6.5 4zM11 0v.5c0 1-.5 1.5-1.5 1.5h-3C5.5 2 5 1.5 5 .5V0h6zM11 16v-.5c0-1-.5-1.5-1.5-1.5h-3c-1 0-1.5.5-1.5 1.5v.5h6z"
          />
        </svg>
        <span data-l10n-id="scroll_page">Page Scrolling</span>
      </button>
      <button
        type="button"
        [class]="showScrollingButton | negativeResponsiveCSSClass : ''"
        id="scrollVertical"
        class="secondaryToolbarButton scrollVertical toggled"
        title="Use Vertical Scrolling"
        data-l10n-id="scroll_vertical"
      >
        <svg style="width: 24px; height: 24px; margin-top: 3px">
          <path
            fill="currentColor"
            d="M9.5 4c1 0 1.5.5 1.5 1.5v5c0 1-.5 1.5-1.5 1.5h-3c-1 0-1.5-.5-1.5-1.5v-5C5 4.5 5.5 4 6.5 4zM11 0v.5c0 1-.5 1.5-1.5 1.5h-3C5.5 2 5 1.5 5 .5V0h6zM11 16v-.5c0-1-.5-1.5-1.5-1.5h-3c-1 0-1.5.5-1.5 1.5v.5h6z"
          />
        </svg>
        <span data-l10n-id="scroll_vertical_label">Vertical Scrolling</span>
      </button>
      <button
        type="button"
        [class]="showScrollingButton | negativeResponsiveCSSClass : ''"
        id="scrollHorizontal"
        class="secondaryToolbarButton scrollHorizontal"
        title="Use Horizontal Scrolling"
        data-l10n-id="scroll_horizontal"
      >
        <svg style="width: 24px; height: 24px; margin-top: 3px">
          <path
            fill="currentColor"
            d="M0 4h1.5c1 0 1.5.5 1.5 1.5v5c0 1-.5 1.5-1.5 1.5H0zM9.5 4c1 0 1.5.5 1.5 1.5v5c0 1-.5 1.5-1.5 1.5h-3c-1 0-1.5-.5-1.5-1.5v-5C5 4.5 5.5 4 6.5 4zM16 4h-1.5c-1 0-1.5.5-1.5 1.5v5c0 1 .5 1.5 1.5 1.5H16z"
          />
        </svg>
        <span data-l10n-id="scroll_horizontal_label">Horizontal Scrolling</span>
      </button>
      <button
        type="button"
        [class]="showScrollingButton | negativeResponsiveCSSClass : ''"
        id="scrollWrapped"
        class="secondaryToolbarButton scrollWrapped"
        title="Use Wrapped Scrolling"
        data-l10n-id="scroll_wrapped"
      >
        <svg style="width: 24px; height: 24px; margin-top: 3px">
          <path
            fill="currentColor"
            d="M5.5 4c1 0 1.5.5 1.5 1.5v5c0 1-.5 1.5-1.5 1.5h-3c-1 0-1.5-.5-1.5-1.5v-5C1 4.5 1.5 4 2.5 4zM7 0v.5C7 1.5 6.5 2 5.5 2h-3C1.5 2 1 1.5 1 .5V0h6zM7 16v-.5c0-1-.5-1.5-1.5-1.5h-3c-1 0-1.5.5-1.5 1.5v.5h6zM13.5 4c1 0 1.5.5 1.5 1.5v5c0 1-.5 1.5-1.5 1.5h-3c-1 0-1.5-.5-1.5-1.5v-5c0-1 .5-1.5 1.5-1.5zM15 0v.5c0 1-.5 1.5-1.5 1.5h-3C9.5 2 9 1.5 9 .5V0h6zM15 16v-.507c0-1-.5-1.5-1.5-1.5h-3C9.5 14 9 14.5 9 15.5v.5h6z"
          />
        </svg>
        <span data-l10n-id="scroll_wrapped_label">Wrapped Scrolling</span>
      </button>

      <button
        type="button"
        [class]="showSpreadButton | negativeResponsiveCSSClass : ''"
        id="spreadNone"
        class="secondaryToolbarButton spreadNone toggled"
        title="Do not join page spreads"
        data-l10n-id="spread_none"
        (click)="onSpreadChange('off')"
      >
        <svg height="16" width="16">
          <path fill="currentColor" d="M6 3c-1 0-1.5.5-1.5 1.5v7c0 1 .5 1.5 1.5 1.5h4c1 0 1.5-.5 1.5-1.5v-7c0-1-.5-1.5-1.5-1.5z" />
        </svg>
        <span data-l10n-id="spread_none_label">No Spreads</span>
      </button>
      <button
        type="button"
        [class]="showSpreadButton | negativeResponsiveCSSClass : ''"
        id="spreadOdd"
        class="secondaryToolbarButton spreadOdd"
        title="Join page spreads starting with odd-numbered pages"
        data-l10n-id="spread_odd"
        (click)="onSpreadChange('odd')"
      >
        <svg style="width: 24px; height: 24px; margin-top: 3px">
          <path
            fill="currentColor"
            d="M10.56 3.5C9.56 3.5 9 4 9 5v6.5c0 1 .5 1.5 1.5 1.5h4c1 0 1.5-.5 1.5-1.5V5c0-1-.5-1.5-1.5-1.5zm1.93 1.2c.8 0 1.4.2 1.8.64.5.4.7 1 .7 1.7 0 .5-.2 1-.5 1.44-.2.3-.6.6-1 .93l-.6.4c-.4.3-.6.4-.7.55-.1.1-.2.2-.3.4h3.2v1.27h-5c0-.5.1-1 .3-1.43.2-.49.7-1 1.5-1.54.7-.5 1.1-.8 1.3-1.02.3-.3.4-.7.4-1.05 0-.3-.1-.6-.3-.77-.2-.2-.4-.3-.7-.3-.4 0-.7.2-.9.5-.1.2-.1.5-.2.9h-1.4c0-.6.2-1.1.3-1.5.4-.7 1.1-1.1 2-1.1zM1.54 3.5C.54 3.5 0 4 0 5v6.5c0 1 .5 1.5 1.54 1.5h4c1 0 1.5-.5 1.5-1.5V5c0-1-.5-1.5-1.5-1.5zm1.8 1.125H4.5V12H3V6.9H1.3v-1c.5 0 .8 0 .97-.03.33-.07.53-.17.73-.37.1-.2.2-.3.25-.5.05-.2.05-.3.05-.3z"
          />
        </svg>
        <span data-l10n-id="spread_odd_label">Odd Spreads</span>
      </button>
      <button
        type="button"
        [class]="showSpreadButton | negativeResponsiveCSSClass : ''"
        id="spreadEven"
        class="secondaryToolbarButton spreadEven"
        title="Join page spreads starting with even-numbered pages"
        data-l10n-id="spread_even"
        (click)="onSpreadChange('even')"
      >
        <svg style="width: 24px; height: 24px; margin-top: 3px">
          <path
            fill="currentColor"
            d="M1.5 3.5C.5 3.5 0 4 0 5v6.5c0 1 .5 1.5 1.5 1.5h4c1 0 1.5-.5 1.5-1.5V5c0-1-.5-1.5-1.5-1.5zm2 1.2c.8 0 1.4.2 1.8.6.5.4.7 1 .7 1.7 0 .5-.2 1-.5 1.4-.2.3-.5.7-1 1l-.6.4c-.4.3-.6.4-.75.56-.15.14-.25.24-.35.44H6v1.3H1c0-.6.1-1.1.3-1.5.3-.6.7-1 1.5-1.6.7-.4 1.1-.8 1.28-1 .32-.3.42-.6.42-1 0-.3-.1-.6-.23-.8-.17-.2-.37-.3-.77-.3s-.7.1-.9.5c-.04.2-.1.5-.1.9H1.1c0-.6.1-1.1.3-1.5.4-.7 1.1-1.1 2.1-1.1zM10.54 3.54C9.5 3.54 9 4 9 5v6.5c0 1 .5 1.5 1.54 1.5h4c.96 0 1.46-.5 1.46-1.5V5c0-1-.5-1.46-1.5-1.46zm1.9.95c.7 0 1.3.2 1.7.5.4.4.6.8.6 1.4 0 .4-.1.8-.4 1.1-.2.2-.3.3-.5.4.1 0 .3.1.6.3.4.3.5.8.5 1.4 0 .6-.2 1.2-.6 1.6-.4.5-1.1.7-1.9.7-1 0-1.8-.3-2.2-1-.14-.29-.24-.69-.24-1.29h1.4c0 .3 0 .5.1.7.2.4.5.5 1 .5.3 0 .5-.1.7-.3.2-.2.3-.5.3-.8 0-.5-.2-.8-.6-.95-.2-.05-.5-.15-1-.15v-1c.5 0 .8-.1 1-.14.3-.1.5-.4.5-.9 0-.3-.1-.5-.2-.7-.2-.2-.4-.3-.7-.3-.3 0-.6.1-.75.3-.2.2-.2.5-.2.86h-1.34c0-.4.1-.7.19-1.1 0-.12.2-.32.4-.62.2-.2.4-.3.7-.4.3-.1.6-.1 1-.1z"
          />
        </svg>
        <span data-l10n-id="spread_even_label">Even Spreads</span>
      </button>

      <button
        type="button"
        [class]="showPropertiesButton | negativeResponsiveCSSClass : ''"
        id="documentProperties"
        class="secondaryToolbarButton documentProperties"
        title="Document Properties…"
        data-l10n-id="document_properties"
        aria-controls="documentPropertiesDialog"
      >
        <svg style="width: 16px; height: 16px; margin-top: 3px" viewBox="0 0 16 16">
          <path fill="currentColor" d="M8 16a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8zM8 2a6 6 0 1 0 6 6 6.006 6.006 0 0 0-6-6z" />
          <path fill="currentColor" d="M8 7a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0V8a1 1 0 0 0-1-1z" />
          <circle fill="currentColor" cx="8" cy="5" r="1.188" />
        </svg>
        <span data-l10n-id="document_properties_label">Document Properties…</span>
      </button>
    </div>
  </div>
</ng-template>
