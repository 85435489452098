import {Routes} from '@angular/router';
import {DirectoryDocumentNoteComponent} from './directory-document-note.component';
import {PermissionViewComponent} from './directory-document-permission/permission-view.component';

export const directoryDocumentRoute: Routes = [
    {
        path: 'permission-view',
        component: PermissionViewComponent,
        data: {
            pageTitle: 'vdrApp.directory.home.title'
        },
    },
    {
        path: 'files',
        children: [{
            path: '**',
            component: DirectoryDocumentNoteComponent,
            data: {
                pageTitle: 'vdrApp.directory.home.title'
            },
        }]
    },
];
