import {Component} from '@angular/core';
import {DirDTO} from './directory-tree.component';
import {SelectedItem} from './document-list.component';
import {DirectoryDocumentService} from './directory-document.service';
import {HttpResponse} from '@angular/common/http';
import {DirectoryDocumentViewSettingsDto} from './dto/directory-document-view-settings-dto.model';
import {ActivatedRoute, Router, UrlSegment} from '@angular/router';
import {PdfDocumentViewerComponent} from '../../account';
import {MatDialog} from '@angular/material/dialog';
import {TreeElementType} from '@entities/directory-document/dto/tree-element';

@Component({
    selector: 'jhi-directory-document',
    templateUrl: 'directory-document-note.component.html',
    styleUrls: ['directory-document.scss'],
})
export class DirectoryDocumentNoteComponent {
    selectedDirectory: DirDTO;
    selectedDocuments: SelectedItem[];
    directoryGenerated: number;
    ddvSettings: DirectoryDocumentViewSettingsDto;
    listDirectorySelection: number;
    refreshDocuments: number;
    currentUrlSegments: UrlSegment[];

    constructor(
        private directoryDocumentService: DirectoryDocumentService,
        private dialog: MatDialog,
        public route: ActivatedRoute,
        public router: Router
    ) {
        // todo cleanup
        this.route.params.subscribe((value) => {
            console.log('route params', value);
        });

        this.route.url.subscribe((value) => {
            this.currentUrlSegments = value;

            directoryDocumentService.findByPath('/' + value.join('/'))
                .then((element) => {
                    if (element.type === TreeElementType.directory) {
                        this.selectedDirectory = new DirDTO(element.id, element.name, element.businessIndex, 'DIRECTORY');
                        console.log('new directory is', this.selectedDirectory);
                        return;
                    }

                    this.dialog.open(PdfDocumentViewerComponent, {
                        width: '100%',
                        height: '100%',
                        minHeight: '100%',
                        maxWidth: '100vw',
                        panelClass: 'pdf-viewer-dialog',
                        autoFocus: false,
                        data: {documentId: element.id}
                    }).beforeClosed().subscribe(() => {
                        // on close load previous directory
                        this.router.navigateByUrl('files/' + this.currentUrlSegments.slice(0, -1).join('/'));
                    });
                    console.log('Element is', element);
                });
            console.log('route url', value, value.join('/'));
        });

        this.route.fragment.subscribe((value) => {
            console.log('route fragment', value);
        });

        this.route.data.subscribe((value) => {
            console.log('route data', value);
        });

        this.route.paramMap.subscribe((value) => {
            console.log('route paramMap', value);
        });

        this.route.queryParamMap.subscribe((value) => {
            console.log('route queryParamMap', value);
        });

        console.log('route', route, router);
        this.directoryDocumentService.getSettings()
            .subscribe((res: HttpResponse<DirectoryDocumentViewSettingsDto>) => {
                this.ddvSettings = res.body;
            });
    }

    onSelectedDictionary(dto: DirDTO) {
        this.selectedDirectory = dto;
        this.selectedDocuments = [];
    }

    onSelectedDocuments(documents: SelectedItem[]) {
        this.selectedDocuments = documents;
    }

    onDirectoryCreaction(random: number) {
        this.directoryGenerated = random;
    }

    onListDirectorySelection(selectedDirectoryId: number) {
        this.listDirectorySelection = selectedDirectoryId;
    }

    onRefreshDocuments(refresh: number) {
        this.refreshDocuments = refresh;
    }
}
