<div fxLayout="column">
    <div fxLayout="column">
        <div class="modal-header">
            <!--<span jhiTranslate="report.archive.dialog.title">-->
            <h4 class="modal-title">Raport dot. wgranych danych</h4>
            <mat-divider></mat-divider>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                    (click)="close()">&times;
            </button>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row" fxFlex fxLayoutAlign="start">
            <div fxFlex class="p-3">
                <button mat-raised-button style="min-width: 70px" [matMenuTriggerFor]="filterMenu" (click)="filterOpen()">
                    <mat-icon>filter_list</mat-icon>
                    <span jhiTranslate="document.filter.search">Filtr</span>
                </button>
            </div>
            <div fxFlex class="p-6">
                <mat-form-field style="width: 100%">
                    <mat-label>Wyszukaj</mat-label>
                    <input #searchText type="text" matInput placeholder="wg. klient lub projektu" (keyup)="searchByText($event)"/>
                </mat-form-field>
            </div>
            <div fxFlex class="p-3">
                   <mat-checkbox color="warn" (change)="groupByClient($event)">wg. klienta</mat-checkbox>
            </div>
            <!--<br/>-->

            <mat-menu #filterMenu="matMenu" class="filter-menu" style="width: 300px">
                <div fxFlex fxLayout="column" fxLayoutAlign="center" *ngIf="reportUploadedCriteriaDto" (click)="$event.stopPropagation();">
                    <div class="filter-item" fxLayout="column">
                        <mat-form-field>
                            <input
                                name="dateFrom"
                                matInput
                                [matDatepicker]="dateFromPicker"
                                [(ngModel)]="reportUploadedCriteriaDto.dateFrom"
                                placeholder="{{'report.date-from' | translate}}"
                                (click)="$event.stopPropagation();">
                            <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
                            <mat-datepicker #dateFromPicker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field>
                            <input
                                name="dateTo"
                                matInput
                                [matDatepicker]="dateToPicker"
                                [(ngModel)]="reportUploadedCriteriaDto.dateTo"
                                placeholder="{{'report.date-to' | translate}}"
                                (click)="$event.stopPropagation();">
                            <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
                            <mat-datepicker #dateToPicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="filter-item mt-2" fxLayout="column">
                        <div align="right">
                            <button
                                mat-raised-button
                                color="warn"
                                (click)="search()">
                                <span class="fa fa-search"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-menu>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row" fxFlex fxLayoutAlign="center" class="pr-3 pl-3 pt-3 pb-1" *ngIf="hasFilters()">
            <mat-form-field #chipList fxFlex="98%">
                <mat-chip-list>
                    <mat-chip *ngIf="reportUploadedCriteriaDto.dateFrom">
                        <span jhiTranslate="report.date-from">Date from</span>
                        <span>&nbsp;{{reportUploadedCriteriaDto.dateFrom | date: 'yyyy-MM-dd'}}</span>

                    </mat-chip>
                    <mat-chip *ngIf="reportUploadedCriteriaDto.dateTo">
                        <span jhiTranslate="report.date-to">Date to</span>
                        <span>&nbsp;{{reportUploadedCriteriaDto.dateTo | date: 'yyyy-MM-dd'}}</span>
                    </mat-chip>
                    <mat-chip color="warn" (removed)="chipsRemoveAll()">
                        <span jhiTranslate="report.clear-filters">Clear filters</span>
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </mat-form-field>
        </div>
    </div>
    <div style="overflow: auto; height: calc(55vh)">
        <mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="client">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b>klient</b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-font content-icon">
                    {{ element.clientName }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="project">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b>projekt</b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-font content-icon">
                    {{ element.projectName }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="range_days">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b>zakres dni </b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-font content-icon">
                    {{ element.reportUploaded.filtered.uploadedBytes  / 1024 / 1024 / 1024 | roundUp  }}  GB /
                    {{ element.reportUploaded.filtered.uploadedBytes  / 1024 / 1024 | number: '1.2-2'  }}  MB
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="active">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b>aktywne </b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-font content-icon">
                    {{ element.reportUploaded.max.uploadedBytes  / 1024 / 1024 / 1024 | roundUp }}  GB /
                    {{ element.reportUploaded.max.uploadedBytes  / 1024 / 1024 | number: '1.2-2' }}  MB
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="invoice">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b>do faktury </b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-font content-icon">
                    {{ element.reportUploaded.sum.uploadedBytes  / 1024 / 1024 / 1024 | roundUp }}  GB /
                    {{ element.reportUploaded.sum.uploadedBytes  / 1024 / 1024 | number: '1.2-2' }}  MB
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="total">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b>wszystkie</b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-font content-icon">
                    {{ element.reportUploaded.total.uploadedBytes  / 1024 / 1024 / 1024 | roundUp }}  GB /
                    {{ element.reportUploaded.total.uploadedBytes  / 1024 / 1024 | number: '1.2-2' }}  MB
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
    </div>
</div>
