<div id="errorWrapper" hidden="true">
  <div id="errorMessageLeft">
    <span id="errorMessage"></span>
    <button type="button" id="errorShowMore" data-l10n-id="error_more_info">More Information</button>
    <button type="button" id="errorShowLess" data-l10n-id="error_less_info" hidden="true">Less Information</button>
  </div>
  <div id="errorMessageRight">
    <button type="button" id="errorClose" data-l10n-id="error_close">Close</button>
  </div>
  <div class="clearBoth"></div>
  <textarea id="errorMoreInfo" hidden="true" readonly="readonly"></textarea>
</div>
