<ng-container [ngTemplateOutlet]="customFindbar ? customFindbar : defaultFindbar"> </ng-container>

<ng-template #defaultFindbar>
  <div
    class="findbar hidden doorHanger"
    [class]="showFindButton | responsiveCSSClass : ''"
    id="findbar"
    [style.transform]="'scale(' + mobileFriendlyZoomScale + ')'"
    [style.transformOrigin]="'left center'"
    [style.left]="findbarLeft"
    [style.top]="findbarTop"
  >
    <ng-container [ngTemplateOutlet]="customFindbarButtons ? customFindbarButtons : defaultFindbarButtons"> </ng-container>
  </div>
</ng-template>

<ng-template #defaultFindbarButtons>
  <pdf-find-input-area [customFindbarInputArea]="customFindbarInputArea"></pdf-find-input-area>
  <pdf-find-highlight-all [class.hidden]="!showFindHighlightAll"></pdf-find-highlight-all>
  <pdf-find-match-case [class.hidden]="!showFindMatchCase"></pdf-find-match-case>
  <pdf-find-current-page-only [class.hidden]="!showFindCurrentPageOnly"></pdf-find-current-page-only>
  <pdf-find-range [class.hidden]="!showFindPageRange"></pdf-find-range>
  <pdf-find-entire-word [class.hidden]="!showFindEntireWord"></pdf-find-entire-word>
  <pdf-find-entire-phrase [class.hidden]="!showFindEntirePhrase"></pdf-find-entire-phrase>
  <pdf-find-ignore-accents [class.hidden]="!showFindIgnoreAccents"></pdf-find-ignore-accents>
  <pdf-find-fuzzily [class.hidden]="!showFindFuzzySearch"></pdf-find-fuzzily>
  <pdf-find-results-count [class.hidden]="!showFindResultsCount"></pdf-find-results-count>
  <pdf-findbar-message-container [class.hidden]="!showFindMessages"></pdf-findbar-message-container>
</ng-template>
