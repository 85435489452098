import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import {ProfileService} from '../profiles/profile.service';
import {AccountService, JhiLanguageHelper, LoginModalService, LoginService, Principal, StateStorageService, UtilsService} from '../../shared';

import {VERSION} from '../../app.constants';
import {ProjectVdr, ProjectVdrService} from '@admin/project-vdr';
import {JhiAlertService, JhiEventManager, JhiLanguageService} from '@upside-cloud/ng-jhipster';
import {ThermsModalService} from '../../shared/therms/therms-modal.service';
import {timer} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {NgxSpinnerService} from 'ngx-spinner';
import {PasswordResetService} from '../../account';
import {TranslateService} from '@ngx-translate/core';
import {DateAdapter} from '@angular/material/core';
import {MenuItem} from 'primeng/api';
import {VerificationCodeModalService} from '../../shared/therms/verification-code-modal.service';

@Component({
    selector: 'jhi-navbar',
    templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {
    private SHOW_SESSION_EXPIRING_DIALOG_MINUTES = 2;
    inProduction: boolean;
    isNavbarCollapsed: boolean;
    languages: any[];
    swaggerEnabled: boolean;
    modalRef: NgbModalRef;
    version: string;
    items: MenuItem[];
    projects: ProjectVdr[];
    minimize: boolean;
    currentProject?: ProjectVdr;
    canAddProject: boolean;

    lastAccessTime: number;
    sessionTimerSource: any;
    sessionTimer = new SessionTimer(this.SHOW_SESSION_EXPIRING_DIALOG_MINUTES);
    showTimerDialog: boolean;
    private SPINNER_TIMEOUT = 2000;
    private SESSION_TIMEOUT_MINUTES = 15;
    // TODO pobrać z właściwości systemowych
    showVerificationCode: boolean;
    private domainUrl: DomainUrl;

    verificationCodeModalRef: NgbModalRef;
    filteredProjects: ProjectVdr[];

    constructor(
        private loginService: LoginService,
        private principal: Principal,
        private loginModalService: LoginModalService,
        private profileService: ProfileService,
        private router: Router,
        private projectVdrService: ProjectVdrService,
        private accountService: AccountService,
        private eventManager: JhiEventManager,
        private languageHelper: JhiLanguageHelper,
        private thermsModalService: ThermsModalService,
        private route: ActivatedRoute,
        private spinner: NgxSpinnerService,
        private passwordService: PasswordResetService,
        private modalService: NgbModal,
        private jhiAlertService: JhiAlertService,
        private languageService: JhiLanguageService,
        private utilsService: UtilsService,
        private translateService: TranslateService,
        private stateStorageService: StateStorageService,
        private verificationCodeModalService: VerificationCodeModalService,
        private dateAdapter?: DateAdapter<Date>,
    ) {
        this.version = VERSION ? 'v' + VERSION : '';
        this.isNavbarCollapsed = true;
        this.utilsService.initNavbarIcons();
    }

    ngOnInit() {
        if (this.router.url !== '' && this.router.url !== '/accessdenied' && this.router.url !== '/project-vdr') {
            this.stateStorageService.storeObjectUrl(this.router.url);
        }

        this.profileService.getProfileInfo().then((profileInfo) => {
            this.inProduction = profileInfo.inProduction;
            this.swaggerEnabled = profileInfo.swaggerEnabled;
            const baseUrl = profileInfo.baseUrl;
            this.domainUrl = new DomainUrl(baseUrl);
            this.init();
        });

        this.principal.getAuthenticationState().subscribe((value) => {
            this.registerChangeInNavbarEvent();
            this.minimize = false;
        });

    }

    findProjectName() {
        if (this.currentProject) {
            return this.currentProject.name;
        } else {
            return 'Wybierz projekt';
        }

    }

    toggleMinimilize() {
        this.minimize = !this.minimize;
    }

    registerChangeInNavbarEvent() {
        this.eventManager.subscribe('authenticationSuccess', (response) => {

            this.init();
        });
        this.eventManager.subscribe('projectListModification', (response) => {
            this.getUserProjects();
            // this.principal.identity(true);
        });

        this.eventManager.subscribe('closeSpinner', (response) => {
            this.spinner.hide();
            // this.principal.identity(true);
        });

        this.registerSessionTimerEvents();
        this.eventManager.subscribe('changeProject', (response) => {
            this.changeProjectClick(response.content.project);
        });
    }

    private showSpinner() {
        this.spinner.show();
        setTimeout(() => {
            this.spinner.hide();
        }, this.SPINNER_TIMEOUT);
    }

    public changeLanguage(langKey: string) {
        this.dateAdapter.setLocale(langKey);
        this.languageService.changeLanguage(langKey);
        localStorage.setItem('lang', langKey);
        this.accountService.saveLanguageToAccount(langKey).subscribe((res) => {
            }, (error) => {
                console.log(error);
            }
        );
    }

    private init() {

        this.currentProject = null;
        this.principal.identity(true).then((userIdentity) => {
            const subdomain = this.domainUrl.findSubdomainFromUrl();
            if (!userIdentity) {
                return;
            }

            this.showSpinner();

            if (subdomain) {
                this.projectVdrService.findByUrl(subdomain).subscribe((projectResponse: HttpResponse<ProjectVdr>) => {
                        const project2: ProjectVdr = projectResponse.body;
                        if (!this.currentProject || project2 && this.currentProject.id !== project2.id) {
                            this.changeProject(project2);

                        }
                    }, (res: HttpResponse<any>) => this.onProjectSubdomainNotFound(res.body)
                );

            } else if (this.isDomainDeployment()) {
                this.currentProject = null;
                // po przetestowaniu uprawnień, do zastanowiena czy potrzebne
                if (this.router.url === 'register') {
                    this.router.navigate(['project-vdr']);
                }

            }

            this.sessionTimerInit();

            this.principal.hasAuthority('PROJECT_MANAGEMENT').then((can) => {
                this.canAddProject = can;
            });

            this.getUserProjects();

        });

    }

    getUserProjects() {
        this.accountService.getUserProjects()
            .subscribe((res: ProjectVdr[]) => {
                    this.projects = res;
                    this.filteredProjects = this.projects;
                }
            );
    }

    collapseNavbar() {
        this.isNavbarCollapsed = true;
    }

    isAuthenticated() {
        return this.principal.isAuthenticated();
    }

    isNavbarAvailable() {
        return this.isAuthenticated() && this.currentProject;
    }

    login() {
        this.modalRef = this.loginModalService.open();
    }

    logout() {
        this.collapseNavbar();
        this.loginService.logout();
        this.router.navigate(['accessdenied']);
    }

    toggleNavbar() {
        this.isNavbarCollapsed = !this.isNavbarCollapsed;
    }

    getImageUrl() {
        return this.isAuthenticated() ? this.principal.getImageUrl() : null;
    }

    getUserIdentity() {
        return this.principal.getUserIdentity();
    }

    changeSubDomainByProjectUrl(project: ProjectVdr) {
        this.domainUrl.redirectToSubdomain(project.www);
    }

    changeProjectClick(project: ProjectVdr) {
        if (!project || this.currentProject && project.id === this.currentProject.id) {
            return;
        }
        if (this.isDomainDeployment()) {
            this.accountService.changeProject(null, null).subscribe(() => {
                    this.changeSubDomainByProjectUrl(project);
                },
                (err) => {
                    this.jhiAlertService.error('error.http.500');
                }
            );
        } else {
            this.changeProject(project);
        }
    }

    changeProject(project: ProjectVdr) {
        this.principal.identity(true).then((u) => {
            if (project.twoPhaseAuthentication && !u.admin) {
                this.changeProjectWithVerificationCodePrompt(project, null);
            } else {
                this.changeProjectWithVerificationCode(project, null);
            }
        });
    }

    private changeProjectWithVerificationCode(project: ProjectVdr, verificationCode: string) {
        const previousProject = this.currentProject;
        // tslint:disable-next-line:no-shadowed-variable
        return this.accountService.changeProject(project, verificationCode).subscribe((project) => {
            this.currentProject = project;
            document.body.style.setProperty('--prime-color', project.mainColor);

            this.principal.identity(true).then((u) => this.onProjectChanged(u));
        }, (err) => {
            if (err.error.title === 'IP_RESTRICTED') {
                alert(this.translateService.instant('error.iprestricted'));

                this.onProjectSubdomainNotFound(null);
                // this.jhiAlertService.error('global.messages.error.ipRestricted', null, null);
            } else if (err.error.title === 'WRONG_CODE') {
                this.changeProjectWithVerificationCodePrompt(project, 'INVALID_CODE');
            }
        });
    }

    changeRouteIfNeededAfterProjectChange() {
        // no admin permissions
        this.principal.hasAnyAuthority(['ADMIN']).then((result) => {
            if (result) {
                // todo fix
                console.warn(this.route);
                // this.router.navigate(['']);
            } else {
                this.router.navigate(['/files']);
            }
        });
    }


    redirectToObjectUrl(): void {
        const redirect = this.stateStorageService.getObjectUrl();
        if (redirect !== null) {
            this.stateStorageService.storeObjectUrl(null);
            this.router.navigate([redirect]);
        }
    }

    async showThermsIfNeeded() {
        return new Promise<void>(async(resolve, reject) => {
            const booleanObservable = await this.accountService.checkThermsNeeded().toPromise();
            if (!booleanObservable) {
                resolve();
                return;
            }
            this.thermsModalService.open().result.then(() => resolve(), () => reject());
        });
    }

    getUserFullname() {
        if (this.getUserIdentity()) {
            return this.getUserIdentity().firstName + ' ' + this.getUserIdentity().lastName;
        } else {
            return null;
        }
    }

    acceptTherms() {
        this.accountService.acceptTherms();
        this.principal.identity(true);
    }

    private registerSessionTimerEvents() {
        this.eventManager.subscribe('sessionTimeoutRefresh', (response) => {
            this.sessionTimeoutRefresh();
        });

        this.sessionTimerSource = timer(1000, 1000);
        this.sessionTimerSource.subscribe((val) => this.calculateRemainingSessionTime(val));
    }

    private calculateRemainingSessionTime(val) {
        const sessionDuration = this.SESSION_TIMEOUT_MINUTES * 60 * 1000 - (new Date().getTime() - this.lastAccessTime);
        this.sessionTimer.minutes = Math.floor(sessionDuration / (60 * 1000));
        const seconds = Math.floor((sessionDuration / 1000) % 60);
        if (sessionDuration <= 0) {
            this.sessionTimer.minutes = 0;
            this.sessionTimer.seconds = '0';
            this.logout();
        } else if (seconds < 10) {
            this.sessionTimer.seconds = '0' + seconds;
        } else {
            this.sessionTimer.seconds = '' + seconds;
        }

        if (this.sessionTimer.minutes === this.SHOW_SESSION_EXPIRING_DIALOG_MINUTES && seconds === 0) {
            this.showTimerDialog = true;
        }

    }

    private sessionTimerInit() {
        this.sessionTimer = new SessionTimer(this.SHOW_SESSION_EXPIRING_DIALOG_MINUTES);
        this.sessionTimeoutRefresh();
    }

    private sessionTimeoutRefresh() {
        this.lastAccessTime = new Date().getTime();
        this.showTimerDialog = false;

    }

    closeTimerDialog() {
        this.showTimerDialog = false;
    }

    refreshSession() {
        this.principal.identity(true).then(() => {
        });
    }

    private async onProjectChanged(u: any) {
        await this.showThermsIfNeeded();
        this.changeRouteIfNeededAfterProjectChange();
    }

    private onProjectSubdomainNotFound(error) {

        // alert('subdomain not found:');
        window.location.replace(this.domainUrl.findDomainUrl() + '/#/project-vdr');
    }

    private findProjectHostName() {
        const parser = document.createElement('a');
        parser.href = document.URL;
        const hostname = parser.hostname;
        return hostname;
    }

    private findProjectProtocol() {
        const parser = document.createElement('a');
        parser.href = document.URL;
        const protocol = parser.protocol;
        return protocol;
    }

    private isDomainDeployment(): boolean {
        const parser = document.createElement('a');
        parser.href = document.URL;
        const hostname = parser.hostname;
        return document.URL.indexOf(this.findProjectHostName()) > -1;
    }

    private changeProjectWithVerificationCodePrompt(project: ProjectVdr, messageType: string) {
        const modelRef = this.verificationCodeModalService.open(project, messageType);

        if (modelRef) {
            modelRef.result.then((result) => {
                this.changeProjectWithVerificationCode(project, result.verificationCode);
            }, (reason) => {
                if (!this.currentProject) {
                    this.router.navigate(['project-vdr']);
                }
            });
        }
    }

    findMainColor() {
        if (this.currentProject) {
            return this.currentProject.mainColor;
        } else {
            return 'red';
        }

    }

    onProjectSearch(text: string) {
        console.log('Search text', text);
        if (!text) {
            this.filteredProjects = this.projects;
            return;
        }

        this.filteredProjects = this.projects.filter((v) => {
            return v.name.toLowerCase().indexOf(text.toLowerCase()) >= 0;
        });
    }
}

class DomainUrl {
    protocol: string;
    domainHost: string;
    private port: string;

    constructor(baseUrl) {
        const parser = document.createElement('a');
        parser.href = baseUrl;
        this.domainHost = parser.hostname;
        this.protocol = parser.protocol;
        this.port = parser.port;
    }

    findPortPostfix() {
        return (this.port ? ':' + this.port : '');
    }

    findSubdomainUrl(subdomain: string): string {
        return this.protocol + '//' + subdomain + '.' + this.domainHost + this.findPortPostfix();
    }

    findDomainUrl(): string {
        return this.protocol + '//' + this.domainHost + this.findPortPostfix();
    }

    redirectToSubdomain(subdomain: string) {
        const subdomainUrl = this.findSubdomainUrl(subdomain) + '/#/project-vdr';
        console.log('redirectToSubdomain:' + subdomainUrl);
        window.location.replace(subdomainUrl);
    }

    isSubdomain(): boolean {
        return document.URL.indexOf(this.findDomainUrl()) === -1 && document.URL.indexOf(this.domainHost) > -1;
    }

    findSubdomainFromUrl(): string {

        if (this.isSubdomain()) {
            const currentHost = new DomainUrl(document.URL).domainHost;
            const subdomain = currentHost.substr(0, currentHost.indexOf('.'));
            return subdomain;
        }

        return null;

    }

}

export class SessionTimer {
    public seconds = '0';

    constructor(
        public minutes: number) {
    }

}



