<div class="d-flex flex-row">
    <div style="width:400px;" class="group-list">
        <div class="group-item d-flex flex-row"
             [ngClass]="(!userCriteriaDto || !userCriteriaDto.selectedGroupId) ? 'user-selected-button' : ''">
            <button style="width:70%;margin: 0 5px;text-align: left"
                    class="btn btn-link"
                    (click)="selectGroup(null)">
                <i class="fa fa-remove"></i>&nbsp;
                <span jhiTranslate="userManagement.allGroups">Wszystkie grupy</span><i class="ml-1">({{allUsersCount}}
                )</i>
            </button>
        </div>

        <div *ngFor="let userGroup of groups; trackBy: trackIdentity" class="group-item d-flex flex-rowx"
             [ngClass]="(userCriteriaDto && userGroup.id==userCriteriaDto.selectedGroupId) ? 'user-selected-button' : ''">
            <button style="width:70%;margin: 0 5px;text-align: left"
                    class="btn btn-link"
                    (click)="selectGroup(userGroup)">
                <i class="fa fa-users"></i>&nbsp;
                <span>{{userGroup.name}}<i class="ml-1">({{getGroupCount(userGroup)}})</i></span>
            </button>
            <div class="group-buttons">
                <button type="submit"
                        (click)="showGroupDialog(userGroup)"
                        replaceUrl="true"
                        queryParamsHandling="merge"
                        class="btn btn-sm btn-link">
                    <span class="fa fa-pencil"></span>
                </button>
                <button type="button"
                        (click)="deleteGroup(userGroup)"
                        replaceUrl="true"
                        queryParamsHandling="merge"
                        class="btn btn-sm btn-link">
                    <span class="fa fa-trash"></span>
                </button>
            </div>
        </div>

    </div>
    <div class="user-card pl-2 pr-2 pt-3" style="width:100%">
        <form (ngSubmit)="criteriaChanged()" name="searchForm" novalidate #searchForm="ngForm">
            <div *ngIf="userCriteriaDto" fxLayout="row" class="mb-2">
                <div fxLayout="column" class="mr-2">
                    <div class="search-input" fxLayout="row">
                        <div fxLayout="column">
                            <input name="search-name"
                                   type="text"
                                   placeholder="{{'userManagement.filter.user-name' | translate}}"
                                   [(ngModel)]="userCriteriaDto.name"
                                   (change)="criteriaNameChanged()"/>
                        </div>
                        <mat-divider [vertical]="true"></mat-divider>
                        <div fxLayout="column">
                            <button
                                mat-icon-button
                                type="submit"
                                style="margin: 0;"
                                [disabled]="!userCriteriaDto.name">
                                <mat-icon>search</mat-icon>
                            </button>
                        </div>
                        <mat-divider [vertical]="true"></mat-divider>
                        <div fxLayout="column">
                            <button
                                mat-icon-button
                                type="button"
                                (click)="criteriaNameRemove()"
                                style="margin: 0"
                                [disabled]="!userCriteriaDto.name">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center">
                    <div class="btn-group flex-btn-group-container">
                        <button type="button"
                                class="btn btn-light btn-user-status"
                                mat-raised-button
                                (click)="chooseAccountStatus(null)"
                                [ngClass]="{'btn-primary': userCriteriaDto.status==null}">
                            <span class="d-none d-md-inline"
                                  jhiTranslate="userManagement.filter.status.all">Wszystkie</span>
                        </button>
                        <button type="button"
                                class="btn btn-light btn-user-status"
                                mat-raised-button
                                (click)="chooseAccountStatus('a')"
                                [ngClass]="{'btn-primary': userCriteriaDto.status=='a'}">
                            <span class="d-none d-md-inline"
                                  jhiTranslate="userManagement.filter.status.active">Aktywny</span>
                        </button>
                        <button type="button"
                                class="btn btn-light btn-user-status"
                                mat-raised-button
                                (click)="chooseAccountStatus('i')"
                                [ngClass]="{'btn-primary': userCriteriaDto.status=='i'}">
                            <span class="d-none d-md-inline"
                                  jhiTranslate="userManagement.filter.status.inactive">Nieaktywny</span>
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <mat-divider></mat-divider>
        <div class="groups-actions" fxLayout="row" fxLayoutAlign="left">
            <div class="btn-group flex-btn-group-container" fxFlex="200px">
                <button type="button"
                        class="btn btn-primary"
                        (click)="showInvitationDialog()"
                        mat-raised-button>
                            <span class="d-none d-md-inline"
                                  jhiTranslate="userManagement.actions.invite">Invite</span>
                </button>
                <button type="button"
                        class="btn btn-light"
                        mat-raised-button
                        (click)="showGroupDialog(null)">
                            <span class="d-none d-md-inline"
                                  jhiTranslate="vdrApp.group.home.createLabel">Create group</span>
                </button>
            </div>
            <div class="btn-group flex-btn-group-container" fxFlex fxLayout="row" fxLayoutAlign="end">
                <button mat-raised-button style="min-width: 70px; margin-left: 0.5rem;" (click)="export()" color="warn"
                        type="button">
                    <mat-icon>get_app</mat-icon>
                </button>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="table-responsive user-table" *ngIf="users">
            <!--panel zapytań -->

            <table class="table">
                <thead>
                <tr jhiSort [(predicate)]="predicate" [(ascending)]="reverse" [callback]="transition.bind(this)">
                    <th jhiSortBy="userEmail">
                        <span jhiTranslate="userManagement.table.email">Login</span>
                        <span class="fa fa-sort"></span>
                    </th>
                    <th></th>
                    <th jhiSortBy="groupName">
                        <span jhiTranslate="userManagement.table.group">Grupa</span>
                        <span class="fa fa-sort"></span>
                    </th>
                    <!--                   <th jhiSortBy="userCreatedDate">
                                           <span jhiTranslate="userManagement.createdDate">Zalogowany</span>
                                           <span class="fa fa-sort"></span>
                                       </th>-->
                    <th jhiSortBy="userCompany">
                        <span jhiTranslate="userManagement.table.company">Nazwa firmy</span>
                        <span class="fa fa-sort"></span>
                    </th>
                    <th jhiSortBy="userCompany">
                        <span jhiTranslate="userManagement.table.phoneNumber">Numer telefonu</span>
                        <span class="fa fa-sort"></span>
                    </th>
                    <th style="width:130px;padding-left:0;padding-right:0"></th>
                </tr>
                </thead>
                <tbody *ngIf="users">
                <tr *ngFor="let user of users; trackBy: trackIdentity" class="group-item"
                    [class.selectedrow]="isUserRowSelected(user)">
                    <td class="d-flex">
                        <div class="login-status"><span class="logged-out"
                                                        [ngClass]="{'logged-in': user| userLogged}">●</span></div>
                        <div>
                            <div *ngIf="user.userFullName!='null null'">
                                <span> {{user.userFullName}}</span>
                            </div>
                            <div *ngIf="user.userFullName=='null null'"><span style="text-transform: uppercase"
                                                                              jhiTranslate="userManagement.table.nonRegisterUser"></span>
                            </div>
                            <div class="hint">{{user.userEmail}}</div>

                        </div>
                    </td>


                    <td>
                        <button class="btn btn-danger btn-sm" (click)="setActive(user, true)" *ngIf="!user.active"
                                [disabled]="currentAccount.id === user.userId"
                                jhiTranslate="userManagement.deactivated">Deactivated
                        </button>
                        <button class="btn btn-success btn-sm" (click)="setActive(user, false)" *ngIf="user.active"
                                [disabled]="currentAccount.id === user.userId"
                                jhiTranslate="userManagement.activated">Activated
                        </button>
                    </td>
                    <td>
                        <div>
                            <span *ngIf="user.groupName">{{ user.groupName }}</span>
                            <div class="hint">{{user.loginDate | date:'yyyy-MM-dd HH:mm'}}</div>
                        </div>
                    </td>
                    <!--  <td attr.title="{{user.userCreatedDate | date:'HH:mm dd.MM.yyyy':'+0300'}}">{{user.userCreatedDate |
                          date:'HH:mm dd.MM.yyyy'}}
                      </td>-->
                    <td>
                        <div>
                            <div>{{user.userCompany}}</div>
                            <div class="hint">{{user.userCompanyPosition}}</div>
                        </div>
                    </td>
                    <td>
                        <div>
                            <div>{{user.userPhoneNumber}}</div>
                        </div>
                    </td>
                    <td style="width: 200px">
                        <div class="btn-group flex-btn-group-container">

                            <div class="group-buttons ">
                                <button type="submit"
                                        (click)="selectUser(user)"
                                        replaceUrl="true"
                                        queryParamsHandling="merge"
                                        class="btn btn-primary btn-sm button-margins"
                                        matTooltip="{{ 'userManagement.table.edit' | translate }}">
                                    <span class="fa fa-pencil"></span>
                                </button>
                                <button type="button"
                                        (click)="deleteUser(user)"
                                        replaceUrl="true"
                                        queryParamsHandling="merge"
                                        class="btn btn-primary btn-sm button-margins"
                                        [disabled]="currentAccount.id === user.userId"
                                        matTooltip="{{ 'userManagement.table.delete' | translate }}">
                                    <span class="fa fa-trash"></span>
                                </button>

                                <button type="button"
                                        class="btn btn-primary btn-sm button-margins"
                                        [matMenuTriggerFor]="settingsMenu"
                                        matTooltip="{{ 'userManagement.table.other.options' | translate }}">
                                    <span class="fa fa-ellipsis-h"></span>
                                </button>

                            </div>

                            <mat-menu #settingsMenu="matMenu">
                                <button mat-menu-item (click)="sendRegistrationEmail(user.userEmail)">
                                    <span class="fa fa-plus-square"></span>&nbsp;
                                    <span jhiTranslate="userManagement.table.link.registration.send"></span>
                                </button>
                                <button mat-menu-item (click)="copyRegistrationLink(user.userEmail)">
                                    <span class="fa fa-copy"></span>&nbsp;
                                    <span jhiTranslate="userManagement.table.link.registration.copy"></span>
                                </button>
                                <button mat-menu-item (click)="sendActivationEmail(user.userEmail)">
                                    <span class="fa fa-at"></span>&nbsp;
                                    <span jhiTranslate="userManagement.table.link.activation.send"></span>
                                </button>
                            </mat-menu>

                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="false">
            <div class="row justify-content-center">
                <jhi-item-count [page]="page" [total]="queryCount" [itemsPerPage]="itemsPerPage"></jhi-item-count>
            </div>
            <div class="row justify-content-center">
                <ngb-pagination [collectionSize]="totalItems" [(page)]="page" [pageSize]="itemsPerPage"
                                [maxSize]="5"
                                [rotate]="true" [boundaryLinks]="true"
                                (pageChange)="loadPage(page)"></ngb-pagination>
            </div>
        </div>
    </div>
    <div class="user-detail-panel" *ngIf="selectedUser">
        <button class="btn close-panel-button" (click)="selectedUser=null"><span class="fa fa-remove"></span>
        </button>
        <mat-divider></mat-divider>
        <mat-tab-group [(selectedIndex)]="selectedTab">

            <mat-tab label="{{ 'userManagement.detailPanel.tabs.permissions' | translate }}" class="custom-mat-tab">
                <div class="user-detail-tab">
                    <h6> {{'userManagement.detailPanel.groupEditMessage' | translate}}
                        {{selectedUser.userEmail}}</h6>


                    <div class="d-flex flex-column pt-2 pb-2">
                        <label class="form-group-label" jhiTranslate="userManagement.detailPanel.groupType">Typ
                            grupy</label>
                        <div class="btn-group flex-btn-group-container mt-2">
                            <button type="button"
                                    class="btn btn-light btn-sm" (click)="changeGroupTypeAdmin(selectedUser,false)"
                                    [ngClass]="{'btn-primary': !selectedUser.groupAdmin}">
                                <span class="fa fa-users"></span>
                                <span class="d-none d-md-inline"
                                      jhiTranslate="userManagement.detailPanel.users">Users</span>
                            </button>
                            <button type="button"
                                    replaceUrl="true"
                                    queryParamsHandling="merge"
                                    class="btn btn-light btn-sm" (click)="changeGroupTypeAdmin(selectedUser,true)"
                                    [ngClass]="{'btn-primary': selectedUser.groupAdmin}">
                                <span class="fa fa-user"></span>
                                <span class="d-none d-md-inline"
                                      jhiTranslate="userManagement.detailPanel.admins">Admins</span>
                            </button>
                        </div>


                    </div>


                    <div>
                        <div class="mt-2 mb-2">
                            <label class="form-group-label" jhiTranslate="userManagement.detailPanel.group"></label>
                        </div>

                        <ng-select name="singleSelect" style="width:250px" (change)="changeGroup(selectedUser)"
                                   [(ngModel)]="selectedUser.groupId">

                            <ng-option [value]="null">{{'userManagement.detailPanel.pickGroup' | translate}}</ng-option>
                            <ng-option *ngFor="let userGroup2 of findFilterdGroups(selectedUser.groupAdmin)"
                                    [value]="userGroup2.id">
                                {{userGroup2.name}}
                            </ng-option>
                        </ng-select>




                    </div>

                    <div class="mt-4 mb-2">


                        <div class="mt-2">
                            <label class="form-group-label"
                                   jhiTranslate="userManagement.detailPanel.qa.title"></label>
                            <div class="btn-group flex-btn-group-container mt-2">
                                <button type="button" (click)="switchQAPermission('QA_DELETE')"
                                        class="btn btn-light btn-sm"
                                        [ngClass]="{'btn-primary': isQAPermissionSelected('QA_DELETE')}">
                                    <span class="d-none d-md-inline"
                                          jhiTranslate="userManagement.detailPanel.qa.delete">Kasowanie</span>
                                    <span class="fa fa-trash"></span>
                                </button>
                                <button type="button" (click)="switchQAPermission('QA_SHARE')"
                                        replaceUrl="true"
                                        queryParamsHandling="merge"
                                        class="btn btn-light btn-sm"
                                        [ngClass]="{'btn-primary': isQAPermissionSelected('QA_SHARE')}">
                                    <span class="d-none d-md-inline"
                                          jhiTranslate="userManagement.detailPanel.qa.forward">Przekazywanie</span>
                                    <span class="fa fa-share"></span>
                                </button>

                                <button type="button" (click)="switchQAPermission('QA_WRITE')"
                                        replaceUrl="true"

                                        queryParamsHandling="merge"
                                        class="btn btn-light btn-sm"
                                        [ngClass]="{'btn-primary': isQAPermissionSelected('QA_WRITE')}">
                                        <span class="d-none d-md-inline"
                                              jhiTranslate="userManagement.detailPanel.qa.write">Zapisy</span>
                                    <span class="fa fa-arrow-down"></span>
                                </button>

                                <button type="button" (click)="switchQAPermission('QA')"
                                        replaceUrl="true"
                                        queryParamsHandling="merge"
                                        class="btn btn-light btn-sm"
                                        [ngClass]="{'btn-primary': isQAPermissionSelected('QA')}">
                                <span class="d-none d-md-inline"
                                      jhiTranslate="userManagement.detailPanel.qa.read">Oczyt</span>
                                    <span class="fa fa-arrow-up"></span>
                                </button>
                                <button type="button" (click)="switchQAPermission(null)"
                                        [disabled]="isQAPermissionSelected(null)"
                                        replaceUrl="true"
                                        queryParamsHandling="merge"
                                        class="btn btn-light btn-sm"
                                        [ngClass]="{'btn-primary': isQAPermissionSelected(null)}">
                                <span class="d-none d-md-inline"
                                      jhiTranslate="userManagement.detailPanel.qa.none">Brak</span>
                                    <span class="fa fa-arrow-up"></span>
                                </button>
                            </div>
                        </div>

                        <jhi-qa-permission-info [groupId]="selectedUser.groupId"
                                                [permissionFunction]="isQAPermissionSelected.bind(this)"
                                                [permission]="selectedUser?.qaPermission"></jhi-qa-permission-info>

                    </div>


                </div>
            </mat-tab>

            <mat-tab label="{{ 'userManagement.detailPanel.tabs.security' | translate }}">
                <div class="p-3">
                    <div class="form-group  d-flex flex-column p-3x">
                        <label class="form-group-label" jhiTranslate="userManagement.detailPanel.ip.title">OGRANICZENIA
                            IP</label>
                        <div class="btn-group flex-btn-group-container mt-2">

                            <button type="button" [disabled]="currentAccount.id === selectedUser.userId"
                                    class="btn btn-light btn-sm" (click)="chooseIpRestriction(true)"
                                    [ngClass]="{'btn-primary': selectedUser.ipRestriction}">
                                <span class="d-none d-md-inline" jhiTranslate="global.yes">TAK</span>
                            </button>
                            <button type="button"
                                    replaceUrl="true" [disabled]="currentAccount.id === selectedUser.userId"
                                    queryParamsHandling="merge"
                                    class="btn btn-light btn-sm" (click)="chooseIpRestriction(false)"
                                    [ngClass]="{'btn-primary': !selectedUser.ipRestriction}">
                                <span class="d-none d-md-inline" jhiTranslate="global.no">NIE</span>
                            </button>


                        </div>
                    </div>

                    <div class="form-group  d-flex flex-column p-3x" *ngIf="selectedUser.ipRestriction">
                        <label class="form-group-label" jhiTranslate="userManagement.detailPanel.ip.type">IP
                            TYPE</label>
                        <div class="btn-group flex-btn-group-container mt-2">
                            <button type="button"
                                    replaceUrl="true"
                                    queryParamsHandling="merge"
                                    class="btn btn-light btn-sm" (click)="chooseIp6Type(false)"
                                    [ngClass]="{'btn-primary': !selectedUser.ip6Type}">
                                <span class="d-none d-md-inline"
                                      jhiTranslate="userManagement.detailPanel.ip.ip4">IP4</span>
                            </button>

                            <button type="button"
                                    class="btn btn-light btn-sm" (click)="chooseIp6Type(true)"
                                    [ngClass]="{'btn-primary': selectedUser.ip6Type}">
                                 <span class="d-none d-md-inline"
                                       jhiTranslate="userManagement.detailPanel.ip.ip6">IP6</span>
                            </button>


                        </div>
                    </div>


                    <div *ngIf="selectedUser.ipRestriction">
                        <label class="form-group-label" jhiTranslate="userManagement.detailPanel.ip.addresses">ADRESY
                            IP</label>
                        <div class="hint"> {{'userManagement.detailPanel.ip.addressHint' | translate}}</div>
                        <div class="input-group w-100 mt-2" style="width:320px;">
                            <input type="text" class="form-control" name="ip4" *ngIf="!selectedUser.ip6Type"
                                   #emailInput="ngModel" placeholder="IP4"
                                   [(ngModel)]="selectedUser.ip4" minlength="12" required maxlength="100"/>
                            <input type="email" class="form-control" name="ip6" *ngIf="selectedUser.ip6Type"
                                   #emailInput="ngModel" placeholder="IP6"
                                   [(ngModel)]="selectedUser.ip6" minlength="12" required maxlength="100"/>
                            <button type="text" (click)="changeSecuritySettings (selectedUser)"
                                    class="btn btn-primary">
                                <span class="fa fa-save"></span>
                            </button>
                        </div>


                    </div>

                    <div class="mt-3"></div>

                    <mat-divider></mat-divider>
                    <div class="form-group  d-flex flex-column mt-3">
                        <label class="form-group-label"
                               jhiTranslate="userManagement.detailPanel.active.title">AKTYWNY</label>
                        <div class="btn-group flex-btn-group-container mt-2">


                            <button type="button"
                                    [disabled]="currentAccount.id === selectedUser.userId"
                                    class="btn btn-light btn-sm"
                                    (click)="selectedUser.enableType = 'enabled';changeSecuritySettings (selectedUser);"
                                    [ngClass]="{'btn-primary': selectedUser.enableType === 'enabled'}">
                                <span class="d-none d-md-inline" jhiTranslate="global.yes">TAK</span>
                            </button>
                            <button type="button"
                                    replaceUrl="true"
                                    queryParamsHandling="merge"
                                    class="btn btn-light btn-sm"
                                    [disabled]="currentAccount.id === selectedUser.userId"
                                    (click)="selectedUser.enableType = 'disabled';changeSecuritySettings (selectedUser);"
                                    [ngClass]="{'btn-primary': selectedUser.enableType === 'disabled'}">
                                <span class="d-none d-md-inline" jhiTranslate="global.no">NIE</span>
                            </button>
                            <button type="button"
                                    replaceUrl="true"
                                    queryParamsHandling="merge"
                                    class="btn btn-light btn-sm"
                                    [disabled]="currentAccount.id === selectedUser.userId"
                                    (click)="selectedUser.enableType = 'todate';changeSecuritySettings (selectedUser);"
                                    [ngClass]="{'btn-primary': selectedUser.enableType === 'todate'}">
                                <span class="d-none d-md-inline"
                                      jhiTranslate="userManagement.detailPanel.active.tillDate">Do Daty</span>
                            </button>

                        </div>
                    </div>

                    <div class="d-flex flex-column mt-4" *ngIf="selectedUser.enableType ==='todate'">

                        <label class="form-group-label" jhiTranslate="userManagement.detailPanel.active.date">DATA
                            WAŻNOSCI</label>

                        <mat-form-field>

                            <input style="width:250px"
                                   name="searchDateFrom"
                                   matInput
                                   [matDatepicker]="searchDateFromPicker"
                                   (dateChange)="changeSecuritySettings (selectedUser);"
                                   [(ngModel)]="selectedUser.dateRestriction"
                                   (click)="$event.stopPropagation();">
                            <mat-datepicker-toggle matSuffix [for]="searchDateFromPicker"></mat-datepicker-toggle>
                            <mat-datepicker #searchDateFromPicker></mat-datepicker>
                        </mat-form-field>


                    </div>

                </div>


            </mat-tab>


            <mat-tab label="{{ 'userManagement.detailPanel.tabs.notifications' | translate }}">
                <div>
                    <div class="form-group  d-flex flex-column p-3">
                        <label class="form-group-label"
                               jhiTranslate="userManagement.detailPanel.notifications.newDocuments">NOWE
                            DOKUMENTY</label>
                        <div class="btn-group flex-btn-group-container mt-2">

                            <button type="button"
                                    class="btn btn-light btn-sm" (click)="chooseNotificationDocuments('DAY')"
                                    [ngClass]="{'btn-primary': selectedUser.notificationDocumentFrequency==='DAY'}">
                                <span class="d-none d-md-inline"
                                      jhiTranslate="userManagement.detailPanel.notifications.daily">Raz dziennie</span>
                            </button>
                            <button type="button"
                                    class="btn btn-light btn-sm" (click)="chooseNotificationDocuments('TWICE_A_DAY')"
                                    [ngClass]="{'btn-primary': selectedUser.notificationDocumentFrequency==='TWICE_A_DAY'}">
                                <span class="d-none d-md-inline"
                                      jhiTranslate="userManagement.detailPanel.notifications.twiceADay">Dwa razy dziennie</span>
                            </button>
                            <button type="button"
                                    replaceUrl="true"
                                    queryParamsHandling="merge"
                                    class="btn btn-light btn-sm" (click)="chooseNotificationDocuments('HOUR_1')"
                                    [ngClass]="{'btn-primary': selectedUser.notificationDocumentFrequency==='HOUR_1'}">
                                <span class="d-none d-md-inline"
                                      jhiTranslate="userManagement.detailPanel.notifications.hourly">Co godzine</span>
                            </button>
                            <button type="button"
                                    replaceUrl="true"
                                    queryParamsHandling="merge"
                                    class="btn btn-light btn-sm" (click)="chooseNotificationDocuments('MINUTES_15')"
                                    [ngClass]="{'btn-primary': selectedUser.notificationDocumentFrequency==='MINUTES_15'}">
                                <span class="d-none d-md-inline"
                                      jhiTranslate="userManagement.detailPanel.notifications.every15minuts">Co 15 minut</span>
                            </button>

                        </div>
                    </div>
                    <mat-divider></mat-divider>

                    <div class="form-group  d-flex flex-column p-3">
                        <label class="form-group-label" jhiTranslate="userManagement.detailPanel.notifications.qa">Q&A
                            POWIADOMIENIA</label>
                        <div class="btn-group flex-btn-group-container mt-2">

                            <button type="button"
                                    class="btn btn-light btn-sm" (click)="chooseNotificationQA('EVERY_QUESTION')"
                                    [ngClass]="{'btn-primary': selectedUser.notificationQa==='EVERY_QUESTION'}">
                                <span class="d-none d-md-inline"
                                      jhiTranslate="userManagement.detailPanel.notifications.everyQuestion">Każde pytanie</span>
                            </button>
                            <button type="button"
                                    replaceUrl="true"
                                    queryParamsHandling="merge"
                                    class="btn btn-light btn-sm" (click)="chooseNotificationQA('ADDRESSED_TO_ME')"
                                    [ngClass]="{'btn-primary': selectedUser.notificationQa==='ADDRESSED_TO_ME'}">
                                <span class="d-none d-md-inline"
                                      jhiTranslate="userManagement.detailPanel.notifications.assignedToMe">Zaadresowane do mnie</span>
                            </button>
                            <button type="button"
                                    replaceUrl="true"
                                    queryParamsHandling="merge"
                                    class="btn btn-light btn-sm" (click)="chooseNotificationQA(null)"
                                    [ngClass]="{'btn-primary': !selectedUser.notificationQa}">
                                <span class="d-none d-md-inline"
                                      jhiTranslate="userManagement.detailPanel.notifications.none">Żadne</span>
                            </button>
                        </div>
                    </div>

                </div>
            </mat-tab>
        </mat-tab-group>


    </div>
</div>

