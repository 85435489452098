<div
  class="toolbar"
  [class.invisible]="!primaryMenuVisible"
  [style.transform]="'scale(' + mobileFriendlyZoomScale + ')'"
  [style.transformOrigin]="'left center'"
  [style.width]="toolbarWidth"
  [style.marginTop]="toolbarMarginTop"
>
  <div id="toolbarContainer">
    <ng-content *ngTemplateOutlet="customToolbar ? customToolbar : defaultToolbar"></ng-content>
    <div id="loadingBar">
      <div class="progress">
        <div class="glimmer"></div>
      </div>
    </div>
  </div>
</div>

<ng-template #defaultToolbar>
  <div id="toolbarViewer">
    <div id="toolbarViewerLeft">
      <pdf-toggle-sidebar [showSidebarButton]="showSidebarButton"></pdf-toggle-sidebar>
      <pdf-find-button [showFindButton]="showFindButton" [textLayer]="textLayer"></pdf-find-button>
      <pdf-paging-area [showPagingButtons]="showPagingButtons"></pdf-paging-area>
    </div>
    <pdf-zoom-toolbar [showZoomButtons]="showZoomButtons" [zoomLevels]="zoomLevels"></pdf-zoom-toolbar>
    <div id="toolbarViewerRight">
      <pdf-hand-tool [showHandToolButton]="showHandToolButton"></pdf-hand-tool>
      <pdf-select-tool [showSelectToolButton]="showHandToolButton"></pdf-select-tool>
      <pdf-rotate-page [showRotateButton]="showRotateButton" [clockwise]="true" [counterClockwise]="false"> </pdf-rotate-page>
      <pdf-rotate-page [showRotateButton]="showRotateButton" [clockwise]="false" [counterClockwise]="true"> </pdf-rotate-page>
      <pdf-presentation-mode [showPresentationModeButton]="showPresentationModeButton"></pdf-presentation-mode>
      <pdf-open-file [showOpenFileButton]="showOpenFileButton"></pdf-open-file>
      <pdf-print [showPrintButton]="showPrintButton"></pdf-print>
      <pdf-download [showDownloadButton]="showDownloadButton"></pdf-download>

      <pdf-editor [showDrawEditor]="showDrawEditor" [showTextEditor]="showTextEditor"></pdf-editor>
      <pdf-toggle-secondary-toolbar [showSecondaryToolbarButton]="showSecondaryToolbarButton"> </pdf-toggle-secondary-toolbar>
    </div>
  </div>
</ng-template>
