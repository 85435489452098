import {ReportEventTypeGroup} from './report-event-type-group.enum';

export enum ReportEventType {
    // FOLDER
    FOLDER_CREATE = <any>'report.event-type.folder.create',
    FOLDER_RENAME = <any>'report.event-type.folder.rename',
    FOLDER_COPY = <any>'report.event-type.folder.copy',
    FOLDER_DELETE = <any>'report.event-type.folder.delete',
    FOLDER_MOVE = <any>'report.event-type.folder.move',
    FOLDER_RESTORE = <any>'report.event-type.folder.restore',
    FOLDER_SHARE = <any>'report.event-type.folder.share',
    FOLDER_TRASH_DELETE = <any>'report.event-type.folder.trash-delete',
    FOLDER_PERMISSION_APPLY = <any>'report.event-type.folder.permission-apply',
    FOLDER_PERMISSION_EXPORT = <any>'report.event-type.folder.permission-export',
    FOLDER_STRUCTURE_EXPORT = <any>'report.event-type.folder.structure-export',
    FOLDER_INDEX_EXPORT = <any>'report.event-type.folder.index-export',
    FOLDER_FILTRED_LIST_EXPORT = <any>'report.event-type.folder.filtered-list-export',

    // DOCUMENT
    DOCUMENT_CREATE = <any>'report.event-type.file.create',
    DOCUMENT_RENAME = <any>'report.event-type.file.rename',
    DOCUMENT_COPY = <any>'report.event-type.file.copy',
    DOCUMENT_DELETE = <any>'report.event-type.file.delete',
    DOCUMENT_MOVE = <any>'report.event-type.file.move',
    DOCUMENT_RESTORE = <any>'report.event-type.file.restore',
    DOCUMENT_SHARE = <any>'report.event-type.file.share',
    DOCUMENT_TRASH_DELETE = <any>'report.event-type.file.trash-delete',
    DOCUMENT_DOWNLOAD = <any>'report.event-type.file.download',
    DOCUMENT_DOWNLOAD_PDF = <any>'report.event-type.file.download-pdf',
    DOCUMENT_DOWNLOAD_ZIP = <any>'report.event-type.file.download-zip',
    DOCUMENT_DOWNLOAD_ENCRYPTED = <any>'report.event-type.file.download-encrypted',
    DOCUMENT_PRINT = <any>'report.event-type.file.print',
    DOCUMENT_VIEW = <any>'report.event-type.file.view',
    DOCUMENT_VIEW_SWITCH_LIMITED = <any>'report.event-type.file.view-switch-limited',
    DOCUMENT_VIEW_SWITCH_NORMAL = <any>'report.event-type.file.view-switch-normal',
    DOCUMENT_VIEW_ZOOM_IN = <any>'report.event-type.file.view-zoom-in',
    DOCUMENT_VIEW_ZOOM_OUT = <any>'report.event-type.file.view-zoom-out',
    DOCUMENT_VIEW_ROTATE_LEFT = <any>'report.event-type.file.view-rotate-left',
    DOCUMENT_VIEW_ROTATE_RIGHT = <any>'report.event-type.file.view-rotate-right',
    DOCUMENT_VIEW_SEARCH = <any>'report.event-type.file.view-search',
    DOCUMENT_VIEW_PASSWORD_COPY = <any>'report.event-type.file.view-password-copy',
    DOCUMENT_VIEW_CLOSED = <any>'report.event-type.file.view-closed',
    DOCUMENT_VIEW_PAGE_VIEW = <any>'report.event-type.file.view-page-view',
    DOCUMENT_PERMISSION_APPLY = <any>'report.event-type.file.permission-apply',
    DOCUMENT_NEW_VERSION = <any>'report.event-type.file.new-version',
    DOCUMENT_NOTE_CREATE = <any>'report.event-type.file.note-create',
    DOCUMENT_MERGE_PDF = <any>'report.event-type.file.merge-pdf',
    DOCUMENT_SEARCH = <any>'report.event-type.file.search',

    // USER
    USER_INVITE = <any>'report.event-type.user.invite',
    USER_REGISTER = <any>'report.event-type.user.register',
    USER_DELETE = <any>'report.event-type.user.delete',
    USER_MOVE = <any>'report.event-type.user.move',
    USER_LIST_EXPORT = <any>'report.event-type.user.list-export',
    USER_AUTHENTICATION = <any>'report.event-type.user.authentication',
    USER_THERMS_ACCEPT = <any>'report.event-type.user.therms-accept',
    USER_2FA_PROJECT_AUTHENTICATION = <any>'report.event-type.user.project.2fa-authentication',
    USER_ACTIVE_CHANGE = <any>'report.event-type.user.active-change',
    USER_IP_RESTRICTION_CHANGE = <any>'report.event-type.user.ip-restriction-change',
    USER_DATE_RESTRICTION_CHANGE = <any>'report.event-type.user.date-restriction-change',
    USER_GROUP_CHANGE = <any>'report.event-type.user.group-change',
    USER_GROUP_TYPE_CHANGE = <any>'report.event-type.user.group-type-change',
    USER_QA_PERMISSION_CHANGE = <any>'report.event-type.user.qa-permission-change',
    USER_DOCUMENT_NOTIFICATION_CHANGE = <any>'report.event-type.user.document-notification-change',
    USER_QA_NOTIFICATION_CHANGE = <any>'report.event-type.user.qa-notification-change',

    // Q & A
    QA_CREATE_QUESTION = <any>'report.event-type.question.create',
    QA_DELETE_QUESTION = <any>'report.event-type.question.delete',
    QA_RESTORE_QUESTION = <any>'report.event-type.question.restore',
    QA_STATUS_CHANGE = <any>'report.event-type.question.status-change',
    QA_PRIORITY_CHANGE = <any>'report.event-type.question.priority-change',
    QA_MARK_RESOLVED = <any>'report.event-type.question.mark-resolved',
    QA_CREATE_ANSWER = <any>'report.event-type.question.create-answer',
    QA_DELETE_ANSWER = <any>'report.event-type.question.delete-answer',

    // PROJECT
    PROJECT_CREATE = <any>'report.event-type.project.create',
    PROJECT_NAME_CHANGE = <any>'report.event-type.project.name-change',
    PROJECT_URL_CHANGE = <any>'report.event-type.project.url-change',
    PROJECT_START_DATE_CHANGE = <any>'report.event-type.project.start-date-change',
    PROJECT_END_DATE_CHANGE = <any>'report.event-type.project.end-date-change',
    PROJECT_AUTO_INDEX_ENABLE = <any>'report.event-type.project.auto-index-enable',
    PROJECT_AUTO_INDEX_DISABLE = <any>'report.event-type.project.auto-index-disable',
    PROJECT_2FA_ENABLE = <any>'report.event-type.project.2fa-enable',
    PROJECT_2FA_DISABLE = <any>'report.event-type.project.2fa-disable',
    PROJECT_VERSIONING_ENABLE = <any>'report.event-type.project.versioning-enable',
    PROJECT_VERSIONING_DISABLE = <any>'report.event-type.project.versioning-disable',
    PROJECT_THERMS_CHANGE = <any>'report.event-type.project.therms-change',
    PROJECT_THERMS_ACCEPT_FREQUENCY_CHANGE = <any>'report.event-type.project.therms-accept-frequency-change',
    PROJECT_LOGOTYPE_CHANGE = <any>'report.event-type.project.logotype-change',
    PROJECT_LOGOTYPE_DELETE = <any>'report.event-type.project.logotype-delete',
    PROJECT_INTERFACE_COLOR_CHANGE = <any>'report.event-type.project.interface-color-change',
    PROJECT_WATERMARK_PERSONALIZATION = <any>'report.event-type.project.watermark-personalization',

    // PROJECT GROUP
    PROJECT_GROUP_CREATE = <any>'report.event-type.project.group-create',
    PROJECT_GROUP_NAME_CHANGE = <any>'report.event-type.project.group-name-change',
    PROJECT_GROUP_DELETE = <any>'report.event-type.project.group-delete',

    // REPORT
    REPORT_EXPORT = <any>'report.event-type.report.export',
    REPORT_VIEW = <any>'report.event-type.report.view',
    REPORT_SHARE = <any>'report.event-type.report.send',
    REPORT_SUBSCRIPTION_CREATE = <any>'report.event-type.report.subscription-create',
    REPORT_SUBSCRIPTION_EDIT = <any>'report.event-type.report.subscription-edit',
    REPORT_SUBSCRIPTION_DELETE = <any>'report.event-type.report.subscription-delete'
}

export const getGroupTypes = (group: any) => {
    switch (group) {
        case ReportEventTypeGroup.FILES_AND_FOLDERS:
            return [
                ReportEventType[ReportEventType.FOLDER_CREATE],
                ReportEventType[ReportEventType.FOLDER_RENAME],
                ReportEventType[ReportEventType.FOLDER_COPY],
                ReportEventType[ReportEventType.FOLDER_DELETE],
                ReportEventType[ReportEventType.FOLDER_MOVE],
                ReportEventType[ReportEventType.FOLDER_RESTORE],
                ReportEventType[ReportEventType.FOLDER_SHARE],
                ReportEventType[ReportEventType.FOLDER_TRASH_DELETE],
                ReportEventType[ReportEventType.FOLDER_STRUCTURE_EXPORT],
                ReportEventType[ReportEventType.FOLDER_INDEX_EXPORT],
                ReportEventType[ReportEventType.FOLDER_FILTRED_LIST_EXPORT],
                ReportEventType[ReportEventType.DOCUMENT_CREATE],
                ReportEventType[ReportEventType.DOCUMENT_RENAME],
                ReportEventType[ReportEventType.DOCUMENT_COPY],
                ReportEventType[ReportEventType.DOCUMENT_DELETE],
                ReportEventType[ReportEventType.DOCUMENT_MOVE],
                ReportEventType[ReportEventType.DOCUMENT_RESTORE],
                ReportEventType[ReportEventType.DOCUMENT_SHARE],
                ReportEventType[ReportEventType.DOCUMENT_TRASH_DELETE],
                ReportEventType[ReportEventType.DOCUMENT_DOWNLOAD],
                ReportEventType[ReportEventType.DOCUMENT_DOWNLOAD_PDF],
                ReportEventType[ReportEventType.DOCUMENT_DOWNLOAD_ZIP],
                ReportEventType[ReportEventType.DOCUMENT_DOWNLOAD_ENCRYPTED],
                ReportEventType[ReportEventType.DOCUMENT_PRINT],
                ReportEventType[ReportEventType.DOCUMENT_VIEW],
                ReportEventType[ReportEventType.DOCUMENT_VIEW_SWITCH_LIMITED],
                ReportEventType[ReportEventType.DOCUMENT_VIEW_SWITCH_NORMAL],
                ReportEventType[ReportEventType.DOCUMENT_VIEW_ZOOM_IN],
                ReportEventType[ReportEventType.DOCUMENT_VIEW_ZOOM_OUT],
                ReportEventType[ReportEventType.DOCUMENT_VIEW_ROTATE_LEFT],
                ReportEventType[ReportEventType.DOCUMENT_VIEW_ROTATE_RIGHT],
                ReportEventType[ReportEventType.DOCUMENT_VIEW_PASSWORD_COPY],
                ReportEventType[ReportEventType.DOCUMENT_VIEW_SEARCH],
                ReportEventType[ReportEventType.DOCUMENT_VIEW_CLOSED],
                ReportEventType[ReportEventType.DOCUMENT_VIEW_PAGE_VIEW],
                ReportEventType[ReportEventType.DOCUMENT_NEW_VERSION],
                ReportEventType[ReportEventType.DOCUMENT_NOTE_CREATE],
                ReportEventType[ReportEventType.DOCUMENT_MERGE_PDF],
                ReportEventType[ReportEventType.DOCUMENT_SEARCH],
            ];
        case ReportEventTypeGroup.PERMISSIONS:
            return [
                ReportEventType[ReportEventType.DOCUMENT_PERMISSION_APPLY],
                ReportEventType[ReportEventType.FOLDER_PERMISSION_APPLY],
                ReportEventType[ReportEventType.FOLDER_PERMISSION_EXPORT],
                ReportEventType[ReportEventType.USER_AUTHENTICATION],
                ReportEventType[ReportEventType.USER_THERMS_ACCEPT],
                ReportEventType[ReportEventType.USER_2FA_PROJECT_AUTHENTICATION],
                ReportEventType[ReportEventType.PROJECT_CREATE],
                ReportEventType[ReportEventType.PROJECT_NAME_CHANGE],
                ReportEventType[ReportEventType.PROJECT_URL_CHANGE],
                ReportEventType[ReportEventType.PROJECT_START_DATE_CHANGE],
                ReportEventType[ReportEventType.PROJECT_END_DATE_CHANGE],
                ReportEventType[ReportEventType.PROJECT_AUTO_INDEX_ENABLE],
                ReportEventType[ReportEventType.PROJECT_AUTO_INDEX_DISABLE],
                ReportEventType[ReportEventType.PROJECT_2FA_ENABLE],
                ReportEventType[ReportEventType.PROJECT_2FA_DISABLE],
                ReportEventType[ReportEventType.PROJECT_VERSIONING_ENABLE],
                ReportEventType[ReportEventType.PROJECT_VERSIONING_DISABLE],
                ReportEventType[ReportEventType.PROJECT_THERMS_CHANGE],
                ReportEventType[ReportEventType.PROJECT_THERMS_ACCEPT_FREQUENCY_CHANGE],
                ReportEventType[ReportEventType.PROJECT_LOGOTYPE_CHANGE],
                ReportEventType[ReportEventType.PROJECT_LOGOTYPE_DELETE],
                ReportEventType[ReportEventType.PROJECT_INTERFACE_COLOR_CHANGE],
                ReportEventType[ReportEventType.PROJECT_WATERMARK_PERSONALIZATION],
            ];
        case ReportEventTypeGroup.QUESTIONS:
            return [
                ReportEventType[ReportEventType.QA_CREATE_QUESTION],
                ReportEventType[ReportEventType.QA_DELETE_QUESTION],
                ReportEventType[ReportEventType.QA_RESTORE_QUESTION],
                ReportEventType[ReportEventType.QA_STATUS_CHANGE],
                ReportEventType[ReportEventType.QA_PRIORITY_CHANGE],
                ReportEventType[ReportEventType.QA_MARK_RESOLVED],
                ReportEventType[ReportEventType.QA_CREATE_ANSWER],
                ReportEventType[ReportEventType.QA_DELETE_ANSWER],
            ];
        case ReportEventTypeGroup.REPORTS:
            return [
                ReportEventType[ReportEventType.REPORT_EXPORT],
                ReportEventType[ReportEventType.REPORT_VIEW],
                ReportEventType[ReportEventType.REPORT_SHARE],
                ReportEventType[ReportEventType.REPORT_SUBSCRIPTION_CREATE],
                ReportEventType[ReportEventType.REPORT_SUBSCRIPTION_EDIT],
                ReportEventType[ReportEventType.REPORT_SUBSCRIPTION_DELETE],
            ];
        case ReportEventTypeGroup.USERS_AND_GROUPS:
            return [
                ReportEventType[ReportEventType.USER_INVITE],
                ReportEventType[ReportEventType.USER_REGISTER],
                ReportEventType[ReportEventType.USER_DELETE],
                ReportEventType[ReportEventType.USER_MOVE],
                ReportEventType[ReportEventType.USER_LIST_EXPORT],
                ReportEventType[ReportEventType.PROJECT_GROUP_CREATE],
                ReportEventType[ReportEventType.PROJECT_GROUP_NAME_CHANGE],
                ReportEventType[ReportEventType.PROJECT_GROUP_DELETE],
                ReportEventType[ReportEventType.USER_ACTIVE_CHANGE],
                ReportEventType[ReportEventType.USER_IP_RESTRICTION_CHANGE],
                ReportEventType[ReportEventType.USER_DATE_RESTRICTION_CHANGE],
                ReportEventType[ReportEventType.USER_GROUP_CHANGE],
                ReportEventType[ReportEventType.USER_GROUP_TYPE_CHANGE],
                ReportEventType[ReportEventType.USER_QA_PERMISSION_CHANGE],
                ReportEventType[ReportEventType.USER_DOCUMENT_NOTIFICATION_CHANGE],
                ReportEventType[ReportEventType.USER_QA_NOTIFICATION_CHANGE],
            ];
    }
};
